// import { useState } from 'react'

// interface ConfirmProps {
//   isOpen: boolean
//   message: string
//   onClose: () => void
//   onConfirm: () => void
// }

// const ConfirmModal = ({ isOpen, message, onClose, onConfirm }) => {
//   const [isVisible, setIsVisible] = useState<boolean>(isOpen)

//   const handleConfirm = () => {
//     onConfirm()
//     setIsVisible(false)
//   }

//   const handleClose = () => {
//     onClose()
//     setIsVisible(false)
//   }

//   return (
//     <>
//       {isVisible && (
//         <div className="overlay">
//           <div className="modal">
//             <div className="message">{message}</div>
//             <div className="actions">
//               <button onClick={handleConfirm}>확인</button>
//               <button onClick={handleClose}>취소</button>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   )
// }

// export default ConfirmModal
import { useState } from 'react'
import Modal from 'react-modal'

import styled from 'styled-components'
import { Navbar, Container, Nav, Button } from 'react-bootstrap'

interface ConfirmProps {
  isOpen: boolean
  message: string
  onClose: () => void
  onConfirm: () => void
}

Modal.setAppElement('#root')

const ConfirmModal = (props: ConfirmProps) => {
  const { isOpen, message, onClose, onConfirm } = props
  const [isVisible, setIsVisible] = useState<boolean>(isOpen)

  const handleConfirm = () => {
    onConfirm()
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '600px',
            height: '400px',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '0px',
            padding: '0',
            border: 'none',
          },
        }}
      >
        <ModalContentStyle className="modal-content">
          <ModalHeaderStyle className="modal-header">
            <h5 className="modal-title">확인</h5>
            <Button type="button" className="btn-close" onClick={handleClose} />
          </ModalHeaderStyle>

          <ModalBodyStyle
            className="modal-body"
            dangerouslySetInnerHTML={{ __html: message }}
          ></ModalBodyStyle>

          <ModatlFooterStyle className="modal-footer">
            <ButtonStyle type="button" className="btn btn-primary" onClick={handleConfirm}>
              확인
            </ButtonStyle>
            <ButtonStyle type="button" className="btn btn-secondary" onClick={handleClose}>
              취소
            </ButtonStyle>
          </ModatlFooterStyle>
        </ModalContentStyle>
      </Modal>
    </>
  )
}
// const ModalStyle = styled(Modal)`
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 600px;
//   /* background-color: #350; */
// `
const ModalContentStyle = styled.div`
  height: 100%;
`
const ModalHeaderStyle = styled.div`
  padding: 1em;
  background-color: #00000055;
`
const ModalBodyStyle = styled.div`
  /* padding: 1em; */
  height: auto 100%;
  padding: 1em;
`
const ModatlFooterStyle = styled.div`
  padding: 1em;
  background-color: #00000011;
`
const ButtonStyle = styled.button`
  width: 120px;
  margin-left: 1em;
`
const PointText = styled.span`
  color: #ff0000;
`
const ItemText = styled.span`
  padding-left: 1em;
`

export default ConfirmModal
