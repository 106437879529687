import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'
import { Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { AxiosError } from 'axios'

import { REMOVE_SELECT_MEDIA_ALL } from '../store/StoreMedia'
import {
  SET_OFFAIR,
  SET_ONAIR,
  SET_SELECTED_AUCTION,
  SET_AUCTIONS,
  SET_AUCTION_STATUS,
  REMOVE_AUCTIONS,
  SET_AUCTION_STATUS_CHANNEL_CONNECTED,
} from '../store/AuctionList'
import { REMOVE_WORKARTS } from '../store/StoreWorkartList'
import { SET_VIDEO_STATUS } from '../store/StoreVideo'
import { SET_CHANNEL_SESSION_CNT } from '../store/StoreChannel'
import { INIT_LAST_BID } from '../store/StoreLastBid'

import AppButton from './comm/AppButton'
import SetMediaModal from './modal/SetMediaModal'
import ConfirmModal from './modal/ConfirmModal'

import { IntergrateManagerType } from '../api/Types'

import { REMOVE_TOKEN } from '../store/AuthToken'
import { removeCookieToken } from '../storage/CookieToken'
import { apiAuction } from '../api/Auction'
import { apiUser } from '../api/UserInterface'
import { broadcasterSdk } from '../ws/SpellClient'

const AuctionControll = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  //---------------------------------------------------------------------------
  // Selector
  //---------------------------------------------------------------------------
  // Auth User Selecotor
  const { userId, userName, isManager, userEmail } = useSelector((state: any) => state.user.auth)
  // 경매 Selecotor
  const isLoaded = useSelector((state: any) => state.auction.list.isLoaded)
  const isSelected = useSelector((state: any) =>
    isLoaded && state.auction.list.status !== 'NONE' && state.auction.list.selectedAuction !== null
      ? true
      : false
  )
  const auctions = useSelector((state: any) =>
    isSelected === true ? state.auction.list.auctions : null
  )
  const selectedAuction = useSelector((state: any) =>
    isSelected ? state.auction.list.selectedAuction : null
  )
  const selectedAuctionId = useSelector((state: any) =>
    isLoaded && isSelected ? state.auction.list.selectedAuction.id : null
  )
  const sessionId = useSelector((state: any) => state.auction.list.wesWebsocketSessionId ?? null)
  // Live 경매방송 상태
  const auctionStatus = useSelector((state: any) => (isSelected ? state.auction.list.status : null))
  // 채널삭제 버튼 활성화 여부
  const isVisibleDeleteChannelButton = useSelector(
    (state: any) => state.auction.list.isVisibleDeleteChannelBtn
  )
  // Bid Table Refresh 여부
  const isRefreshBidTable = useSelector((state: any) => state.auction.bidTable.isRefresh)
  const lastBidWindow = useSelector((state: any) =>
    state.auction.lastBid.data ? state.auction.lastBid.data : null
  )
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // React State
  // 응찰자 수
  const [bidUserCount, setBidUserCount] = useState(0)
  // 미디어 설정 모달 오픈 여부
  const [isOpen, setIsOpen] = useState(false)
  // 컨펌 모달 창 오픈 여부
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false)
  //---------------------------------------------------------------------------
  // Axios 에러 핸들러
  /**
   * AxiosError Handler
   * @param error
   */
  const axiosErrorHandler = (error: any) => {
    console.error('AxiosError: ', error.response)
    if (error.response?.status === 401) {
      dispatch(REMOVE_TOKEN())
      dispatch(REMOVE_AUCTIONS())
      removeCookieToken()
      if (error.response?.data?.payload?.isExpiredToken === true) {
        alert('로그인 인증이 만료되었습니다. 재 로그인이 필요합니다.')
      } else if (error.response?.data?.payload?.isMissingApiKey === true) {
        alert('API Key가 없습니다. 관라자에게 문의하세요')
      } else if (error.response?.data?.payload?.isInvalidApiKey === true) {
        alert('인증서가 유효하지 않습니다. 재 로그인이 필요합니다.')
      }
      navigate('/login')
    } else alert(error.message)
  }

  //---------------------------------------------------------------------------
  // 경매선택 셀렉트박스
  const { register } = useForm()

  // 경매선택 버튼 비활성화
  const isDisableSelect: boolean = false

  /**
   * 경매 선택시
   * @param e
   */
  const onchangeAuction = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // console.log('onchangeAuction', e.target.value)
    const selectedAuction = auctions.filter((auction: any) => auction.id === e.target.value)[0]
    dispatch(SET_SELECTED_AUCTION(selectedAuction))
    // openBiddingWindow()
    // setBiddingWindowAuctionId(selectedAuction.id)
    // dispatch(INIT_AUCTION_STATUS())
  }

  /**
   * 경매목록을 가져온다.
   */
  const onSelectList = async () => {
    try {
      const replyData = await apiAuction.list()
      console.log('reply auction list: ', replyData)
      if (replyData) {
        // console.log('replyData.auctions: ', replyData.auctions)
        if (replyData.auctions && replyData.auctions.length > 0) {
          dispatch(SET_AUCTIONS(replyData))
        } else {
          alert('라이브 경매가 없습니다.')
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        axiosErrorHandler(error)
      } else {
        alert('서버와의 통신에 실패했습니다. 잠시 후 다시 시도해주세요.')
      }
    }
  }
  useEffect(() => {
    if (!isLoaded) {
      onSelectList()
    } else if (auctions.length === 0) {
      console.log('auctions.length === 0')
      alert('라이브 경매가 없습니다.')
    }
  }, [isLoaded])
  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------
  // 현재 접속자수 / 응찰 참여자수
  const userCnt = useSelector((state: any) => state.auction.channel.sessionCnt)

  // 응찰자 수 조회
  const getBidUserCount = async (auctionId: string) => {
    console.group('응찰자 수 조회')
    // api call 응찰자 수 조회
    const reply = await apiAuction.getBidUserCount(auctionId)
    if (reply.success) {
      console.log('getBidUserCount.', reply)
      setBidUserCount(reply.count)
    } else {
      console.warn('getBidUserCount fail.', reply)
    }
    console.groupEnd()
  }
  useEffect(() => {
    if (isRefreshBidTable) {
      getBidUserCount(selectedAuctionId)
    }
  }, [isRefreshBidTable])

  useEffect(() => {
    if (selectedAuctionId) {
      getBidUserCount(selectedAuctionId)
    }
  }, [selectedAuctionId])
  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------
  // 미디어 설정
  // 미디어 설정 모달이 닫힐 때 호출되는 콜백 함수
  const onApplySetMedia = async () => {
    // console.log('onApplySetMedia')
    // broadcasterClient.deleteStream()
    if (isOnAir === false) {
      broadcasterSdk.muteMedia()
    }
    setIsOpen(false)
  }
  // Redux State : auction SET_MEDIA 상태로 변환
  const resetSetMedia = () => {
    dispatch(SET_AUCTION_STATUS({ status: 'SET_MEDIA' }))
  }
  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------
  // 경매채널 생성 => 경매채널 삭제 => 경매종료
  /**
   * 라이브 경매 생성 버튼 클릭시
   * @param data
   */
  const onCreateChannel = async () => {
    // console.log(data)
    const { id, title } = selectedAuction
    try {
      const channelInfo = await apiAuction.createChannel(id, title, userId)
      console.log('reply create channel: ', channelInfo)
      dispatch(SET_AUCTION_STATUS({ status: 'CREATED_CHANNEL' }))
      // setBiddingWindowAuctionId(id)
      // if (channelInfo) {
      //   console.log('channelInfo.channel: ', channelInfo.channel)

      // }
    } catch (error) {
      if (error instanceof AxiosError) {
        axiosErrorHandler(error)
      } else {
        alert('서버와의 통신에 실패했습니다. 잠시 후 다시 시도해주세요.')
      }
    }
  }

  /**
   * 라이브 경매 삭제
   */
  const onDeleteChannel = async () => {
    const { id } = selectedAuction
    try {
      await broadcasterSdk.removeMyStream()
      await broadcasterSdk.leave()
      dispatch(REMOVE_WORKARTS())
      dispatch(REMOVE_SELECT_MEDIA_ALL())

      const replyData = await apiAuction.deleteChannel(id)
      console.log('reply deleteChannel: ', replyData)
      if (replyData.success) {
        // dispatch(SET_AUCTION_STATUS({ status: 'INIT_AUCTION' }))
        dispatch(REMOVE_AUCTIONS())
        alert('라이브 경매가 삭제되었습니다.')
      } else {
        alert('라이브경매 삭제에 실패하였습니다. 잠시 후 다시 시도해주세요.')
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        axiosErrorHandler(error)
      } else {
        alert('라이브경매 삭제에 실패하였습니다. 잠시 후 다시 시도해주세요.')
      }
    }
  }

  /**
   * 라이브 경매 종료
   */
  const onCloseAuction = async () => {
    dispatch(SET_AUCTION_STATUS({ status: 'CLOSE' }))
    if (bidWindow) bidWindow.close()
  }
  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------
  // auctionStatus
  // auctionStatus function
  var bidWindow: WindowProxy | null = null
  const openBiddingWindow = () => {
    console.log('openBiddingWindow')
    bidWindow = window.open(
      '/bidding',
      'Bidding',
      'left=0, top=0, screenX=0, screenY=0, fullscreen=yes, toolbar=no, menubar=no, location=no, status=no, scrollbars=no, resizable=no'
    )
  }
  /**
   * CREATED_CHANNEL 상태
   *  - WES Websocket connect
   *  - connect event Handler 연결
   */
  const handlerCreateChannel = async () => {
    // broadcasterSdk.addEventListener('connected', connectedEventHandler)
    console.log('broadcasterSdk. connect')
    await broadcasterSdk.connect()
  }
  /**
   * CHANNEL_CONNECTED 상태
   * - WES API IntergrateManager 호출
   */
  const handlerChannelConnected = async () => {
    const user: IntergrateManagerType = {
      userId,
      userName,
      isManager,
      isBroadcaster: true,
      userAttr: JSON.stringify({
        userEmail: userEmail,
      }),
      channelId: selectedAuction.id,
    }
    try {
      if (sessionId === null) {
        console.error('sessionId is null')
        resetSetMedia()
        alert('경매 생성 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.')
      } else {
        const replyData = await apiUser.intergrateManager(sessionId, user)
        console.log('reply intergrate manager: ', replyData)
        if (replyData) {
          dispatch(SET_AUCTION_STATUS({ status: 'INTERGRATED' }))
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        axiosErrorHandler(error)
        resetSetMedia()
      } else {
        resetSetMedia()
        alert('서버와의 통신에 실패했습니다. 잠시 후 다시 시도해주세요.')
      }
    }
  }
  /**
   * INTERGRATED 상태
   * - WES Channel enter
   * - enterSuccess event Handler 연결
   */
  const handlerIntergrated = () => {
    // broadcasterSdk.addEventListener('enterSuccess', enterSuccessEventHandler)
    console.log('enter channel: ', selectedAuction.id)
    broadcasterSdk.enter(selectedAuction.id)
  }

  /**
   * Channel Entered 상태
   * - 라이브 경매 페이지 OPEN 상태로 변경
   */
  const handlerChannelEntered = async () => {
    try {
      const replyData = await apiAuction.auctionOpen(selectedAuction.id)
      if (replyData) {
        console.log('replay auction open: ', replyData)
        dispatch(SET_AUCTION_STATUS({ status: 'CHANNEL_OPEND' }))
        dispatch(SET_VIDEO_STATUS('READY'))
        alert('경매가 생성되었습니다.')
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        axiosErrorHandler(error)
        resetSetMedia()
      } else {
        resetSetMedia()
        alert('서버와의 통신에 실패했습니다. 잠시 후 다시 시도해주세요.')
      }
    }
  }
  /**
   * 라이브경매 종료 처리
   */
  const handlerClose = async () => {
    try {
      await broadcasterSdk.endAuction()
      await broadcasterSdk.removeMyStream()
      await broadcasterSdk.leave()
      dispatch(REMOVE_SELECT_MEDIA_ALL())

      // const replyData = await apiAuction.deleteChannel(selectedAuction.id)
      const replyData = await apiAuction.auctionEnd(selectedAuction.id)
      if (replyData && replyData.success === true) {
        console.log('replay auction close: ', replyData)
        // dispatch(SET_AUCTION_STATUS({ status: 'CLOSED' }))
        dispatch(REMOVE_AUCTIONS())
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        axiosErrorHandler(error)
      } else {
        alert('서버와의 통신에 실패했습니다. 잠시 후 다시 시도해주세요.')
      }
    }
  }
  //-----------------------------------------------
  // auctionStatus
  if (auctionStatus) {
    switch (auctionStatus) {
      case 'LOADED':
        break
      case 'SELECTED_AUCTION':
        // openBiddingWindow()
        break
      case 'SET_MEDIA':
        // handlerSetMedia()
        break
      case 'CREATED_CHANNEL':
        // 1. wes websocket 연결
        console.log('action CREATED_CHANNEL ..')
        handlerCreateChannel()
        break
      case 'CHANNEL_CONNECTED':
        // 2. Integrate user api call
        console.log('action CHANNEL_CONNECTED ..')
        handlerChannelConnected()
        break
      case 'INTERGRATED':
        // 3. Enter channel
        handlerIntergrated()
        break
      case 'CHANNEL_ENTERED':
        // 4. Channel open (live auction open)
        handlerChannelEntered()
        break
      case 'CHANNEL_OPEND':
        console.log('SelectAuction. STATUS: CHANNEL_OPEND')
        openBiddingWindow()
        break
      case 'ONAIR':
        break
      case 'OFFAIR':
        break
      case 'BID_STARTED':
        break
      case 'CLOSE':
        handlerClose()
        break
      case 'CLOSED':
        alert('라이브 경매가 종료되었습니다.')
        break
    }
  }

  //---------------------------------------------------------------------------
  // ON AIR 상태
  const isOnAir = useSelector((state: any) =>
    !!state.auction.list.selectedAuction ? state.auction.list.isOnAir : false
  )
  const isOpend = useSelector((state: any) =>
    !!state.auction.list.selectedAuction && state.auction.list.status === 'CHANNEL_OPEND'
      ? true
      : false
  )
  const isEndLot = useSelector((state: any) => state.auction.workList.status === 'END_LOT')
  const onAir = async () => {
    if (isOnAir) return
    if (isOpend) {
      console.log('onAir')
      //broadcasterSdk
      broadcasterSdk.unmuteMedia()
      broadcasterSdk.onAir()
      dispatch(SET_ONAIR())
    } else {
      alert('ON AIR 준비 상태가 아닙니다.')
    }
  }

  const offAir = async () => {
    if (!isOnAir) return
    if (isOpend) {
      console.log('offAir')
      broadcasterSdk.muteMedia()
      broadcasterSdk.offAir()
      dispatch(SET_OFFAIR())
    }
  }
  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------
  // Broadcaster Sender
  const updateConnectUserCnt = async () => {
    broadcasterSdk.retieveSubscribers()
  }
  //---------------------------------------------------------------------------
  // Broadcaster Action Event Handler
  const onlyOnce = () => {
    console.group('Broadcaster. Regist Event Listener')
    console.log('======= Only Once =======')
    console.groupEnd()
    broadcasterSdk.addEventListener('connected', (e: any) => {
      console.group('Broadcaster. channel connected')
      console.log('sessionId:', e.sessionId)
      console.groupEnd()
      dispatch(
        SET_AUCTION_STATUS_CHANNEL_CONNECTED({
          sessionId: e.sessionId,
        })
      )
    })
    broadcasterSdk.addEventListener('enterSuccess', (e: any) => {
      console.group('#### Broadcaster. enterSuccess')
      console.log(e)
      updateConnectUserCnt()
      console.groupEnd()
      dispatch(SET_AUCTION_STATUS({ status: 'CHANNEL_ENTERED' }))
    })
    broadcasterSdk.addEventListener('entered', e => {
      console.group('Broadcaster. entered')
      console.log(e)
      updateConnectUserCnt()
      console.groupEnd()
    })
    broadcasterSdk.addEventListener('listSessions', e => {
      console.group('Broadcaster. list_sessions', e)
      console.log(e)
      // dispatch(SET_CHANNEL_SESSION_CNT({ sessionCnt: e.sessionCnt }))
      console.groupEnd()
    })
    broadcasterSdk.addEventListener('listSubscribers', e => {
      console.group('Broadcaster. listSubscribers', e)
      console.log('#####')
      console.log(e)
      console.log('subscribers_cnt: ', e.subscribersCnt)
      dispatch(SET_CHANNEL_SESSION_CNT({ sessionCnt: e.subscribersCnt }))
      // dispatch(SET_CHANNEL_SESSION_CNT({ sessionCnt: e.sessionCnt }))
      console.groupEnd()
    })
    broadcasterSdk.addEventListener('left', e => {
      console.group('Broadcaster. leave')
      console.log(e)
      updateConnectUserCnt()
      console.groupEnd()
    })
    broadcasterSdk.addEventListener('remoteStreamChanged', e => {
      console.group('Broadcaster. remoteStreamChanged')
      console.log(e)
      console.groupEnd()
    })
    broadcasterSdk.addEventListener('turnedOnAir', e => {
      console.log('Broadcaster. turnedOnAir')
      // broadcasterSdk.unmuteMedia()
    })
    broadcasterSdk.addEventListener('turnedOffAir', e => {
      console.log('Broadcaster. turnedOffAir')
      // broadcasterSdk.muteMedia()
    })
  }
  useEffect(onlyOnce, [])
  //---------------------------------------------------------------------------

  const handleOpenConfirmModal = () => {
    setIsConfirmOpen(true)
  }

  const handleCloseConfirmModal = () => {
    setIsConfirmOpen(false)
  }

  const handleConfirm = () => {
    setIsConfirmOpen(false)
    onCloseAuction()
  }
  //---------------------------------------------------------------------------

  useEffect(() => {
    // onSelectList()
    dispatch(REMOVE_AUCTIONS())
    dispatch(INIT_LAST_BID())
  }, [])

  useEffect(() => {
    if (lastBidWindow) {
      console.log('lastBidWindow: ', lastBidWindow)
      openBiddingWindow()
    }
  }, [lastBidWindow])

  return (
    <>
      <SelectAuctionStyle>
        <FormStyle>
          <Form.Select
            {...register('selectAuction')}
            onChange={e => onchangeAuction(e)}
            disabled={isDisableSelect}
          >
            {auctions && auctions.length > 0 ? (
              auctions.map((auction: any, idx: number) => (
                <option key={auction.id} value={auction.id} data-name={auction.title}>
                  {auction.title} : {auction.location} & Live {auction.live_date_fm}
                </option>
              ))
            ) : (
              <option value="">라이브 경매가 없습니다.</option>
            )}
          </Form.Select>
        </FormStyle>
      </SelectAuctionStyle>

      <LiveControlBoxStyle>
        <ItemStyle>
          <span>현재 접속자 수 : </span>
          <span>{userCnt}</span>
        </ItemStyle>
        <ItemStyle>
          <span>응찰 참여자 수 : </span>
          <span>{bidUserCount}</span>
        </ItemStyle>
        <ItemBtnStyle>
          {!!auctionStatus && auctionStatus === 'SELECTED_AUCTION' ? (
            <>
              <ButtonStyle variant="primary" onClick={() => setIsOpen(true)}>
                1. 설정
              </ButtonStyle>
            </>
          ) : (
            <ButtonStyle
              variant={auctionStatus === 'SET_MEDIA' ? 'danger' : 'secondary'}
              onClick={() => setIsOpen(true)}
              disabled
            >
              설정
            </ButtonStyle>
          )}
          <SetMediaModal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            onApplySetMedia={() => onApplySetMedia()}
            ariaHideApp={false}
          />
        </ItemBtnStyle>
        <ItemBtnStyle>
          <ButtonGroupStyle>
            {!auctionStatus || auctionStatus === 'SELECTED_AUCTION' ? (
              ''
            ) : (
              <ButtonStyle
                variant={
                  auctionStatus === 'SET_MEDIA'
                    ? 'primary'
                    : auctionStatus === 'CHANNEL_OPEND' && isOnAir === false
                    ? 'danger'
                    : 'secondary'
                }
                onClick={() => onCreateChannel()}
                disabled={auctionStatus !== 'SET_MEDIA' ? true : false}
              >
                {auctionStatus === 'SET_MEDIA' ? '2. 경매채널생성' : '경매채널생성'}
              </ButtonStyle>
            )}
          </ButtonGroupStyle>
        </ItemBtnStyle>
        <ItemBtnStyle>
          {!!auctionStatus && auctionStatus === 'CHANNEL_OPEND' ? (
            <ButtonStyle
              variant={isOnAir ? 'danger' : 'primary'}
              onClick={() => onAir()}
              disabled={isOnAir ? true : false}
            >
              {isOnAir ? 'ON AIR 화면송출' : '3. ON AIR 화면송출'}
            </ButtonStyle>
          ) : (
            ''
          )}
        </ItemBtnStyle>

        <ItemBtnStyle>
          <ButtonGroupStyle>
            {!!auctionStatus && auctionStatus === 'CHANNEL_OPEND' && isOnAir ? (
              <ButtonStyle
                variant="primary"
                onClick={() => handleOpenConfirmModal()}
                disabled={isEndLot ? false : true}
              >
                ■ 경매종료
              </ButtonStyle>
            ) : (
              ''
            )}
          </ButtonGroupStyle>
        </ItemBtnStyle>
      </LiveControlBoxStyle>

      <ConfirmModal
        isOpen={isConfirmOpen}
        onClose={handleCloseConfirmModal}
        onConfirm={handleConfirm}
        message="<p>경매 종료시 아래 내용을 확인하세요</p>
        <p>
          <p class='item'>경매 종료 시 아래 내용을 진행합니다.</p>
          <p class='item'>1. 응찰 마감</p>
          <p class='item'>2. 경매 종료 처리</p>
          <p class='item'>3. 응찰 데이터와 낙찰 데이터가 기록됨</p>
          <p class='item'>4. 종료된 경매는 <span class='point'>다시 시작할 수 없습니다</span></p>
        </p>
        <p>위 내용을 모두 확인하였으며, 경매 종료를 진행하시겠습니까?</p>"
      />
    </>
  )
}

const SelectAuctionStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const FormStyle = styled(Form)`
  width: 100%;
`

const LiveControlBoxStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 4px 4px 4px 16px;
`
const ItemStyle = styled.div`
  padding-right: 50px;
`
const ItemBtnStyle = styled.div`
  padding-right: 10px;
`
const ButtonGroupStyle = styled.div`
  padding-left: 4px;
`
const ButtonStyle = styled(Button)`
  width: 200px;
`
export default AuctionControll
