/**
 * WebRTC Select Media Redux Storage
 */

import { createSlice } from '@reduxjs/toolkit'

export type MediaState = {
  selectAudioInId: string | null
  selectAudioOutId: string | null
  selectVideoId: string | null
  selectConstraintsId: string | null
  mediaStreamConstraints: MediaStreamConstraints | {}
}

const initialMediaState: MediaState = {
  selectAudioInId: null,
  selectAudioOutId: null,
  selectVideoId: null,
  selectConstraintsId: null,
  mediaStreamConstraints: {},
}

export const mediaSlice = createSlice({
  name: 'selectedMedia',
  initialState: initialMediaState,
  reducers: {
    SET_SELECT_AUDIO_OUT: (state, action) => {
      state.selectAudioOutId = action.payload.selectAudioOutId
    },
    REMOVE_SELECT_AUDIO_OUT: (state, action) => {
      state.selectAudioOutId = null
    },
    SET_SELECT_MEDIA: (state, action) => {
      state.selectAudioInId = action.payload.selectAudioInId
      state.selectVideoId = action.payload.selectVideoId
      state.selectConstraintsId = action.payload.selectConstraintsId
      state.mediaStreamConstraints = action.payload.mediaStreamConstraints
    },
    REMOVE_SELECT_MEDIA: state => {
      state.selectAudioInId = null
      state.selectVideoId = null
      state.selectConstraintsId = null
      state.mediaStreamConstraints = {}
    },
    REMOVE_SELECT_MEDIA_ALL: state => {
      state.selectAudioInId = null
      state.selectAudioOutId = null
      state.selectVideoId = null
      state.selectConstraintsId = null
      state.mediaStreamConstraints = {}
    },
  },
})

export const {
  SET_SELECT_AUDIO_OUT,
  REMOVE_SELECT_AUDIO_OUT,
  SET_SELECT_MEDIA,
  REMOVE_SELECT_MEDIA,
  REMOVE_SELECT_MEDIA_ALL,
} = mediaSlice.actions

export default mediaSlice.reducer
