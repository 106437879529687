import { apiClient } from './ApiClient'
import { AxiosError } from 'axios'
import { ReplyError } from './Types'

class Auth {
  /**
   * 로그인
   * @param email
   * @param password
   * @returns ReplyLogin
   */
  login = async (email: string, password: string): Promise<ReplyLogin | ReplyError> => {
    try {
      const response = await apiClient.post('/auth/login', {
        email,
        password,
      })
      const reply: ReplyLogin = response.data
      return reply
      // return reply: ReplyLogin = response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log(error.response?.data)
        if (error.status === 401 && !!error.response?.data.payload?.isInvalidUser) {
          console.log('401')
        }
        const reply: ReplyError = error.response?.data
        return reply
      }
      console.error('Auth.ts login error', error)
      throw error
    }
  }
  refreshToken = async (refreshToken: string): Promise<ReplyRefreshToken | ReplyError> => {
    try {
      const response = await apiClient.post('/auth/refresh', {
        refreshToken,
      })
      return response.data
    } catch (error) {
      throw error
    }
  }
}
export const apiAuth = new Auth()

export interface ReplyLogin {
  tokens: {
    accessToken: string
    refreshToken: string
  }
  user: {
    id: string
    email: string
    name: string
    isManager: boolean
  }
}
export type ReplyRefreshToken = {
  tokens: {
    accessToken: string
    refreshToken: string
  }
}

export function instanceOfReplyError(object: any): object is ReplyError {
  return object.error !== undefined
}
