import { Table } from 'react-bootstrap'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

const LotInfo = () => {
  const statusLot = useSelector((state: any) =>
    state.auction.workList.isLoaded &&
    state.auction.workList.datas &&
    state.auction.workList.datas.length > 0
      ? state.auction.workList.datas.filter(
          (wa: any) => wa.lot === state.auction.workList.currentLotNum
        )[0]
      : null
  )

  return (
    <Block>
      <LotInfoBlock>
        <LotAuthorNameStyle>{statusLot.author_nm}</LotAuthorNameStyle>
        <LotWorkNameStyle>{statusLot.work_nm}</LotWorkNameStyle>
        <LotWorkImageStyle src={statusLot.wa_img} />
      </LotInfoBlock>
    </Block>
  )
}
const Block = styled.div``
const LotInfoBlock = styled.div``
const LotAuthorNameStyle = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`
const LotWorkNameStyle = styled.div`
  font-size: 1rem;
  color: #777;
`
const LotWorkImageStyle = styled.img`
  width: 80%;
`
export default LotInfo
