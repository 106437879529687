import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCookieToken, removeCookieToken } from '../storage/CookieToken'
import { Link } from 'react-router-dom'

import { REMOVE_TOKEN } from '../store/AuthToken'
import { useEffect } from 'react'
import { REMOVE_AUCTIONS } from '../store/AuctionList'

function Logout() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // // store에 저장된 Access Token
  // const accessToken = useSelector((state: any) => state.accessToken)
  // // Cookie에 저장된 Refresh Token
  // const refreshToken = getCookieToken()

  async function logout() {
    dispatch(REMOVE_TOKEN())
    dispatch(REMOVE_AUCTIONS())
    removeCookieToken()
    navigate('/')
  }

  useEffect(() => {
    logout()
  }, [])

  return (
    <>
      <Link to="/" />
    </>
  )
}
export default Logout
