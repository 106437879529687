import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import { Navbar, Container, Nav } from 'react-bootstrap'
import styled from 'styled-components'

import NumberConvert from '../lib/utils/NumberConvert'
import { broadcasterSdk } from '../ws/SpellClient'

function Bidding() {
  const dispatch = useDispatch()

  // const selectedAuction = null
  // const lastBid = null

  const isLoaded = useSelector((state: any) => state.auction.list.isLoaded)
  const isSelected = useSelector((state: any) =>
    isLoaded && state.auction.list.status !== 'NONE' && state.auction.list.selectedAuction !== null
      ? true
      : false
  )
  const selectedAuction = useSelector((state: any) =>
    isSelected ? state.auction.list.selectedAuction : null
  )

  const lastBid = useSelector((state: any) =>
    state.auction.lastBid.data ? state.auction.lastBid.data : null
  )
  // const selectedAuctionId = useSelector((state: any) =>
  //   state.auction.list.isLoaded && state.auction.list.selectedAuction
  //     ? state.auction.list.selectedAuction.id
  //     : null
  // )

  // const onlyOnce = () => {
  //   if (selectedAuctionId === null) return
  //   broadcasterSdk.addEventListener('bidded', (e: any) => {
  //     console.log('Broadcaster. bidded. e:', e)
  //   })
  //   broadcasterSdk.addEventListener('appliedBid', (e: any) => {
  //     console.log('Broadcaster. appliedBid. e:', e)
  //   })
  // }
  // useEffect(onlyOnce, [])
  useEffect(() => {
    console.log('selectedAuction:', selectedAuction)
  }, [selectedAuction])

  return (
    <Block>
      <NavbarBlock bg="dark" variant="dark" expand="lg" collapseOnSelect>
        <Container>
          <Navbar.Brand>
            {/* <input type="text" id="auctionId" value={auctionId} /> */}
            {/* <div>
              <SpanTitle>선택된 경매가 없습니다.</SpanTitle>
            </div> */}
            {selectedAuction != null ? (
              <div>
                <SpanTitle>{selectedAuction.title}</SpanTitle>
                <SpanLocation>
                  {selectedAuction.location} & Live {selectedAuction.live_date_fm}
                </SpanLocation>
              </div>
            ) : (
              <div>
                <SpanTitle>선택된 경매가 없습니다.</SpanTitle>
              </div>
            )}
          </Navbar.Brand>
        </Container>
      </NavbarBlock>
      <BodyBlock>
        {lastBid && lastBid.isConfirm == false && lastBid.bid_loc === 'O' ? (
          <BidedBox>
            <BidedBoxTitle>{NumberConvert.numberWithCommas(lastBid.bid_price)}</BidedBoxTitle>
            <BidedBoxPaddle>{lastBid.paddle_num}</BidedBoxPaddle>
          </BidedBox>
        ) : (
          <NoneBlock />
        )}
      </BodyBlock>
    </Block>
  )
}
const Block = styled.div`
  height: 100vh;
  width: 100vw;
`
const NavbarBlock = styled(Navbar)`
  padding: 0;
`
const SpanTitle = styled.span`
  font-size: 0.7em;
  font-weight: bold;
  padding: 0;
`
const SpanLocation = styled.span`
  font-size: 0.7em;
  font-weight: 500;
  padding: 0 0 0 0.7em;
`
const BodyBlock = styled.div``
const NoneBlock = styled.div`
  display: flex;
  height: 100vh;
  margin-top: -87px;
  background-color: #000;
`
const BidedBox = styled.div`
  display: flex;
  height: 100vh;
  margin-top: -87px;
  border: 80px solid #ee5555;
  border-top: 151.98px solid #ee5555;
  flex-direction: column;
  justify-content: center;
`
const BidedBoxTitle = styled.div`
  font-size: 15em;
  font-weight: bold;
  text-align: center;
  line-height: normal;
`
const BidedBoxPaddle = styled.div`
  font-size: 25em;
  font-weight: bold;
  text-align: center;
  line-height: normal;
`

export default Bidding
