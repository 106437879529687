/**
 * Auth Access Token Redux Storage
 */

import { createSlice } from '@reduxjs/toolkit'

const tokenDuration = {
  // accessToken: new Date(Date.now() + 1000 * 60 * 60),
  accessToken: 1000 * 600,
}

export type TokenState = {
  authenticated: boolean
  accessToken: string | null
  userId: string | null
  userName: string | null
  userEmail: string | null
  isManager: boolean
  expireTime: number | null
}

const initialTokenState: TokenState = {
  authenticated: false,
  accessToken: null,
  userId: null,
  userName: null,
  userEmail: null,
  isManager: false,
  expireTime: null,
}

export const tokenSlice = createSlice({
  name: 'auth',
  initialState: initialTokenState,
  reducers: {
    SET_TOKEN: (state, action) => {
      state.authenticated = true
      state.accessToken = action.payload
      state.expireTime = new Date().getTime() + tokenDuration.accessToken
    },
    SET_USER: (state, action) => {
      state.userId = action.payload.id
      state.userName = action.payload.name
      state.userEmail = action.payload.email
      state.isManager = action.payload.isManager
    },
    REMOVE_TOKEN: state => {
      state.authenticated = false
      state.accessToken = null
      state.expireTime = null
      state.userId = null
      state.userName = null
      state.userEmail = null
      state.isManager = false
    },
  },
})

export const { SET_TOKEN, SET_USER, REMOVE_TOKEN } = tokenSlice.actions

export default tokenSlice.reducer
