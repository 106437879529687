import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { apiAuth, instanceOfReplyError } from '../api/Auth'
import { setCookieAccessToken, setCookieToken } from '../storage/CookieToken'
import { SET_TOKEN, SET_USER } from '../store/AuthToken'

import styled from 'styled-components'
import { Form, Button } from 'react-bootstrap'

import FormContainer from '../components/FormContainer'
import Header from '../components/Header'
import AppButton from '../components/comm/AppButton'

function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // useForm을 사용하여 form을 관리한다.
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, errors },
  } = useForm()

  // onSubmit 함수를 만들어서 form을 제출할 때 실행되도록 한다.
  const onSubmit = async (data: any) => {
    // console.log(data)

    const replyData = await apiAuth.login(data.email, data.password)

    if (instanceOfReplyError(replyData)) {
      console.log('error: ', replyData.message)
      return
    } else {
      // refreshToken을 cookie에 저장한다.
      setCookieToken(replyData.tokens.refreshToken)
      setCookieAccessToken(replyData.tokens.accessToken)
      // accessToken, user을 redux에 저장한다.
      await dispatch(SET_TOKEN(replyData.tokens.accessToken))
      await dispatch(SET_USER(replyData.user))

      // dispatch를 사용하여 login 액션을 실행한다.
      // dispatch({ type: 'login', payload: data })
      // navigate를 사용하여 홈으로 이동한다.
      navigate('/')
    }
  }
  const onInvalid = (data: any) => {
    console.log(data)
    console.error('input invalid')
  }
  return (
    <Block>
      <Header />
      <main className="py-3">
        <FormContainer>
          <h3>Log in</h3>

          <Form onSubmit={handleSubmit(onSubmit, onInvalid)}>
            <Form.Group controlId="email">
              <Form.Label>ID</Form.Label>
              <Form.Control
                type="text"
                // name="email"
                aria-invalid={!isDirty ? undefined : errors.email ? 'true' : 'false'}
                {...register('email', {
                  required: 'id is required',
                })}
                placeholder="enter your id"
              />
              {errors.name && errors.name.type === 'required' && <div>id is required</div>}
            </Form.Group>

            <InputBlankBlock />

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                // name="password"
                {...register('password', {
                  required: 'password is required',
                })}
              />
              {errors.password && errors.password.type === 'required' && (
                <div>password is required</div>
              )}
            </Form.Group>

            <BlankBlock />

            <AppButton type="submit" disabled={isSubmitting} width={'100%'}>
              Login
            </AppButton>
            {/* <Button type="submit" variant="primary" disabled={isSubmitting}>
              Login
            </Button> */}
          </Form>
        </FormContainer>
      </main>
    </Block>
  )
}
const Block = styled.div`
  height: 100%;
`
const BlankBlock = styled.div`
  height: 30px;
`
const InputBlankBlock = styled.div`
  height: 6px;
`
export default Login
