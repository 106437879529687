/**
 * WS Channel Event Redux Storage
 */
import { createSlice } from '@reduxjs/toolkit'

export type ChannelState = {
  sessionCnt: number
}

const initialChannelState: ChannelState = {
  sessionCnt: 0,
}
export const channelSlice = createSlice({
  name: 'channel',
  initialState: initialChannelState,
  reducers: {
    SET_CHANNEL_SESSION_CNT: (state, action) => {
      state.sessionCnt = action.payload.sessionCnt
    },
  },
})
export const { SET_CHANNEL_SESSION_CNT } = channelSlice.actions
export default channelSlice.reducer
