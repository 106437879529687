import React from 'react'
import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

type AuthRouteProps = {
  children: ReactNode
}

const AuthRoute = ({ children }: AuthRouteProps) => {
  const isAuth = useSelector((state: any) => state.user.auth.authenticated)
  return !isAuth ? <Navigate to="/login" /> : <>{children}</>
}
export default AuthRoute
