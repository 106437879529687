import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

const HOUR = 60 * 60
interface TimerProps {
  seconds: number
}

const Timer = ({ seconds }: React.PropsWithChildren<TimerProps>) => {
  // const mm = Math.floor((seconds % HOUR) / 60)
  // const hh = Math.floor(seconds / HOUR)
  // const ss = seconds % 60

  const hh = Math.floor(seconds / (1000 * 60 * 60))
  const mm = Math.floor((seconds % (1000 * 60 * 60)) / (1000 * 60))
  const ss = Math.floor((seconds % (1000 * 60)) / 1000)

  const formatTime = (time: number) => {
    return time >= 10 ? time : '0' + time
  }

  return (
    <Block>
      {seconds > 0 ? (
        <div>
          {formatTime(hh)} : {formatTime(mm)} : {formatTime(ss)}
        </div>
      ) : (
        <div>경매를 곧 시작합니다.</div>
      )}
    </Block>
  )
}
const Block = styled.div`
  width: 100%;
  height: 422.66px;
  line-height: 0;
  background-color: #000000aa;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 70px;
`
// const BlockTimerCount = styled.div`
//   width: 100%;
// `
export default Timer
