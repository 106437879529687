/**
 * Bid Event Redux Storage
 */

import { createSlice } from '@reduxjs/toolkit'

export type BidItem = {
  a_id: string
  wa_id: string
  bid_price: number
  bid_loc: string
  paddle_num?: number
  bid_type?: string
  isConfirm: boolean
  isWinBid: boolean
  bid_time_fm: string
  confirm_dt_fm?: string
  winbid_dt_fm?: string
  lot: number
  email?: string
  name?: string
  isLastConfirm?: boolean
}
export type BidListState = {
  list: BidItem[]
  isRefresh: boolean
  lastConfirmBid?: BidItem
  bidPriceList?: number[]
}
const initialBidListState: BidListState = {
  list: [],
  isRefresh: false,
}
export const bidListSlice = createSlice({
  name: 'bidTable',
  initialState: initialBidListState,
  reducers: {
    INIT_BID_LIST: state => {
      state.list = []
      state.isRefresh = false
      state.lastConfirmBid = undefined
      state.bidPriceList = []
    },
    REFRESH_BID_LIST: state => {
      state.isRefresh = true
    },
    SET_BID_LIST: (state, action) => {
      state.list = action.payload.list
      state.isRefresh = false
      action.payload.list.filter((item: BidItem) => {
        if (item.isLastConfirm) {
          state.lastConfirmBid = item
        }
      })
    },
    ADD_BID_ITEM: (state, action) => {
      state.list = [action.payload].concat(state.list)
      // state.list.push(action.payload)
    },
    CONFIRM_BID_ITEM: (state, action) => {
      state.list.map((item, index) => {
        if (
          item.a_id === action.payload.a_id &&
          item.wa_id === action.payload.wa_id &&
          item.bid_price === action.payload.bid_price &&
          item.bid_loc === action.payload.bid_loc
        ) {
          state.list[index].isConfirm = true
          state.list[index].confirm_dt_fm = action.payload.confirm_dt_fm
        }
      })
    },
    WIN_BID_ITEM: (state, action) => {
      state.list.map((item, index) => {
        if (
          item.a_id === action.payload.a_id &&
          item.wa_id === action.payload.wa_id &&
          item.bid_price === action.payload.bid_price &&
          item.bid_loc === action.payload.bid_loc
        ) {
          state.list[index].isWinBid = true
          state.list[index].winbid_dt_fm = action.payload.winbid_dt_fm
        }
      })
    },
    SET_BID_PRICE_LIST: (state, action) => {
      state.bidPriceList = action.payload
    },
  },
})
export const {
  INIT_BID_LIST,
  REFRESH_BID_LIST,
  SET_BID_LIST,
  ADD_BID_ITEM,
  CONFIRM_BID_ITEM,
  WIN_BID_ITEM,
  SET_BID_PRICE_LIST,
} = bidListSlice.actions
export default bidListSlice.reducer
