/**
 * Refresh Token Cookie Storage
 */

import { Cookies } from 'react-cookie'

const cookies = new Cookies()

const tokenDuration = {
  accessToken: new Date(Date.now() + 1000 * 60 * 60 * 24),
  refreshToken: new Date(Date.now() + 1000 * 60 * 60 * 24 * 2),
}

export const setCookieToken = (refreshToken: string) => {
  return cookies.set('refreshToken', refreshToken, {
    sameSite: 'strict',
    path: '/',
    expires: tokenDuration.refreshToken,
  })
}

export const getCookieToken = () => {
  return cookies.get('refreshToken')
}

export const removeCookieToken = () => {
  return cookies.remove('refreshToken')
}

export const setCookieAccessToken = (accessToken: string) => {
  return cookies.set('accessToken', accessToken, {
    sameSite: 'strict',
    path: '/',
    expires: tokenDuration.accessToken,
  })
}

export const getCookieAccessToken = () => {
  return cookies.get('accessToken')
}

export const removeCookieAccessToken = () => {
  return cookies.remove('accessToken')
}
