import { apiClient } from './ApiClient'
import { IntergrateManagerType, IntergrateUserType, ReplyIntergrateUserType } from './Types'

class UserInterface {
  intergrateManager = async (
    sessionId: string,
    user: IntergrateManagerType
  ): Promise<ReplyIntergrateUserType> => {
    try {
      const response = await apiClient.post(`/user/manager/${sessionId}`, user)
      return response.data
    } catch (e) {
      console.log('intergrateManager error', e)
      throw e
    }
  }
  intergrateUser = async (
    sessionId: string,
    user: IntergrateUserType
  ): Promise<ReplyIntergrateUserType> => {
    try {
      console.log('intergrateUser', sessionId, user)
      const response = await apiClient.post(`/user/${sessionId}`, user)
      return response.data
    } catch (e) {
      console.log('intergrateUser error', e)
      throw e
    }
  }
}
export const apiUser = new UserInterface()
