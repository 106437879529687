import { AxiosError } from 'axios'
import { Table } from 'react-bootstrap'
import styled from 'styled-components'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { removeCookieToken } from '../../storage/CookieToken'
import { REMOVE_TOKEN } from '../../store/AuthToken'
import { apiAuction } from '../../api/Auction'
import { SET_WORKARTS } from '../../store/StoreWorkartList'
import { useEffect } from 'react'
import { ReplyWorkartList } from '../../api/Types'

const WorkartList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const selectedId = useSelector((state: any) => state.auction.list.selectedAuctionId)
  const selectedAuction = useSelector((state: any) => state.auction.list.selectedAuction)
  const isLoaded = useSelector((state: any) => state.auction.workList.isLoaded)
  const workarts = useSelector((state: any) => state.auction.workList.datas)

  let workartItem = (
    <tr key="nolist">
      <td colSpan={2}>작품이 없습니다.</td>
    </tr>
  )
  if (isLoaded && workarts.length > 0) {
    workartItem = workarts.map((workart: any) => {
      return (
        <tr key={workart.wa_id}>
          <td>{workart.lot}</td>
          <td>
            {workart.author_nm}: {workart.work_nm}
          </td>
        </tr>
      )
    })
  }

  const axiosErrorHandler = (error: any) => {
    console.error('AxiosError: ', error.response)
    if (error.response?.status === 401) {
      dispatch(REMOVE_TOKEN())
      removeCookieToken()
      if (error.response?.data?.payload?.isExpiredToken === true) {
        alert('로그인 인증이 만료되었습니다. 재 로그인이 필요합니다.')
      } else if (error.response?.data?.payload?.isMissingApiKey === true) {
        alert('API Key가 없습니다. 관라자에게 문의하세요')
      } else if (error.response?.data?.payload?.isInvalidApiKey === true) {
        alert('인증서가 유효하지 않습니다. 재 로그인이 필요합니다.')
      }
      navigate('/login')
    } else alert(error.message)
  }

  const doSelectWorkartList = async () => {
    try {
      const resData: ReplyWorkartList = await apiAuction.listWorkart(selectedAuction.id)
      if (resData && resData.list.length > 0) {
        console.log('reply workart list: ', resData.list)
        // await dispatch(SET_WORKARTS(resData))
        await dispatch(SET_WORKARTS({ data: resData, currentLot: selectedAuction.current_lot }))
      } else if (resData && resData.list.length === 0) {
        alert('등록된 작품이 없습니다.')
      } else {
        alert('작품 목록을 가져오는데 실패했습니다.')
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        axiosErrorHandler(error)
      } else {
        alert('서버와의 통신에 실패했습니다. 잠시 후 다시 시도해주세요.')
      }
    }
  }

  // if (selectedId) {
  //   console.log('selectedId: ', selectedId)
  //   doSelectWorkartList()
  // }

  useEffect(() => {
    // console.log('workarts: ', workarts)
    if (selectedAuction) {
      // console.log('selectedId: ', selectedId)
      doSelectWorkartList()
    }
  }, [selectedAuction])

  console.log('[Render] WorkartList')

  return (
    <Block>
      <Table striped bordered hover>
        <StyledTHead>
          <tr>
            <th>LOT</th>
            <th>작가: 작품명</th>
          </tr>
        </StyledTHead>
        <StyledTBody>{workartItem}</StyledTBody>
      </Table>
    </Block>
  )
}
const Block = styled.div`
  /* width: 800px;
  height: 600px; */
  /* width: 100%; */
  /* 
  background: #dadada; */
`
const StyledTHead = styled.thead`
  font-size: 0.8em;
`
const StyledTBody = styled.thead`
  font-size: 0.8em;
`
export default WorkartList
