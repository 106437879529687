import React from 'react'
import logo from './logo.svg'
import './App.css'
import { useEffect, useState } from 'react'

// import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Login from './pages/Login'
import Logout from './pages/Logout'
import AuthRoute from './components/AuthRoute'
import Bidding from './pages/Bidding'

function App() {
  // const authenticated = useSelector((state: any) => state.user.auth.authenticated)

  //----------------------------------------------
  // 페이지 새로고침 방지
  const preventClose = (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = '' //Chrome에서 동작하도록; deprecated
  }
  useEffect(() => {
    ;(() => {
      window.addEventListener('beforeunload', preventClose)
    })()

    return () => {
      window.removeEventListener('beforeunload', preventClose)
    }
  }, [])
  //----------------------------------------------

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <AuthRoute>
              <Home />
            </AuthRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/logout"
          element={
            <AuthRoute>
              <Logout />
            </AuthRoute>
          }
        />
        <Route
          path="/bidding"
          element={
            <AuthRoute>
              <Bidding />
            </AuthRoute>
          }
        />
      </Routes>
    </Router>
  )
}
export default App

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   )
// }
// export default App
