import { apiClient } from './ApiClient'
import {
  ReplyError,
  ReplyAuctionList,
  ReplyDeleteChannel,
  ReplyWorkartList,
  ChannelInfo,
  ReplyCommSuccess,
  ReplyGetBidList,
  ReplyPostBidSite,
  ReplyPatchWinBid,
  ReplyGetBidUserCount,
} from './Types'
class Auction {
  /**
   * 경매 목록
   * @returns ReplyAuctionList
   */
  list = async (): Promise<ReplyAuctionList> => {
    try {
      const response = await apiClient.get('/auction/list')
      return response.data
    } catch (error) {
      // console.log('Auction.ts list error', error)
      // if (error instanceof AxiosError) {
      //   console.log(error.response?.data)
      //   const reply: ReplyError = error.response?.data
      //   return reply
      // }
      console.error('Auction.ts list error', error)
      throw error
    }
  }

  /**
   * 경매 작품 목록
   * @param auctionId
   * @returns
   */
  listWorkart = async (auctionId: string): Promise<ReplyWorkartList> => {
    try {
      const response = await apiClient.get(`/auction/workarts/${auctionId}`)
      return response.data
    } catch (error) {
      console.error('Auction.ts listWorkart error', error)
      throw error
    }
  }

  /**
   * Create Auction Channel
   * @param id
   * @param name
   * @param userId
   * @returns
   */
  createChannel = async (id: string, name: string, userId: string): Promise<ChannelInfo> => {
    try {
      const response = await apiClient.post('/auction/create', {
        id,
        name,
        userId,
      })
      return response.data
    } catch (error) {
      console.error('Auction.ts create error', error)
      throw error
    }
  }

  /**
   * Delete Auction Channel
   * @param id
   * @returns
   */
  deleteChannel = async (id: string): Promise<ReplyCommSuccess> => {
    try {
      const response = await apiClient.delete(`/auction/delete/${id}`)
      // if (response.status === 204) {
      //   return {
      //     status: 204,
      //     message: '삭제되었습니다.',
      //   }
      // }
      return response.data
    } catch (error) {
      console.error('Auction.ts delete error', error)
      throw error
    }
  }

  /**
   * 라이브 경매 초기화
   * 경매 상태 : READY
   * 현재 lot 초기화
   * 비딩 테이블 초기화 (데이터 삭제)
   * @param auctionId
   * @returns
   */
  initialLiveAuction = async (auctionId: string): Promise<ReplyCommSuccess> => {
    try {
      const response = await apiClient.delete(`/auction/initial/${auctionId}`)
      return response.data
    } catch (error) {
      console.error('Auction.ts initialLiveAuction error', error)
      throw error
    }
  }

  /**
   * 경매 READY (경매 페이지 오픈 전 상태)
   * @param auctionId
   * @returns
   */
  auctionReady = async (auctionId: string): Promise<ReplyCommSuccess> => {
    try {
      const response = await apiClient.patch(`/auction/status/READY/${auctionId}`)
      return response.data
    } catch (error) {
      console.error('Auction.ts auctionReady error', error)
      throw error
    }
  }

  /**
   * 경매 페이지 오픈
   * @param auctionId
   * @returns
   */
  auctionOpen = async (auctionId: string): Promise<ReplyCommSuccess> => {
    try {
      const response = await apiClient.patch(`/auction/status/OPEN/${auctionId}`)
      return response.data
    } catch (error) {
      console.error('Auction.ts auctionOpen error', error)
      throw error
    }
  }

  /**
   * 경매 ONAIR
   * @param auctionId
   * @returns
   */
  auctionOnair = async (auctionId: string): Promise<ReplyCommSuccess> => {
    try {
      const response = await apiClient.patch(`/auction/status/ONAIR/${auctionId}`)
      return response.data
    } catch (error) {
      console.error('Auction.ts auctionOnair error', error)
      throw error
    }
  }

  /**
   * 경매 종료
   * @param auctionId
   * @returns
   */
  auctionEnd = async (auctionId: string): Promise<ReplyCommSuccess> => {
    try {
      const response = await apiClient.patch(`/auction/status/END/${auctionId}`)
      return response.data
    } catch (error) {
      console.error('Auction.ts auctionEnd error', error)
      throw error
    }
  }

  /**
   * 현재 진행 중인 작품 번호 업데이트
   * @param auctionId
   * @param nextLotNum
   * @returns
   */
  patchCurrentLot = async (auctionId: string, nextLotNum: number): Promise<ReplyCommSuccess> => {
    try {
      const response = await apiClient.patch(`/auction/current-lot`, {
        auction_id: auctionId,
        current_lot: nextLotNum,
      })
      return response.data
    } catch (error) {
      console.error('Auction.ts patchCurrentLot error', error)
      throw error
    }
  }

  //---------------------- 경매 입찰 ----------------------
  // 입찰 목로 조회
  getBidList = async (auctionId: string, lot: number): Promise<ReplyGetBidList> => {
    try {
      const response = await apiClient.get(`/auction/bid/${auctionId}/${lot}`)
      return response.data
    } catch (error) {
      console.error('Auction.ts getBidList error', error)
      throw error
    }
  }
  // 현장 입찰 및 확정 처리
  postBidSite = async (
    auctionId: string,
    waId: string,
    bidPrice: number
  ): Promise<ReplyPostBidSite> => {
    try {
      const response = await apiClient.post(`/auction/bid/site`, {
        a_id: auctionId,
        wa_id: waId,
        bid_price: bidPrice,
      })
      return response.data
    } catch (error) {
      console.error('Auction.ts postBidSite error', error)
      throw error
    }
  }
  // 온라인 입찰 확정 처리
  patchBidOnline = async (
    auctionId: string,
    waId: string,
    bidPrice: number
  ): Promise<ReplyPostBidSite> => {
    try {
      const response = await apiClient.patch(`/auction/bid/online`, {
        a_id: auctionId,
        wa_id: waId,
        bid_price: bidPrice,
      })
      return response.data
    } catch (error) {
      console.error('Auction.ts patchBidOnline error', error)
      throw error
    }
  }
  // 낙찰 처리
  patchBidWin = async (
    auctionId: string,
    waId: string,
    bidPrice: number,
    bidLoc: string,
    paddleNum: number | null
  ): Promise<ReplyPatchWinBid> => {
    try {
      const response = await apiClient.patch(`/auction/bid/win`, {
        a_id: auctionId,
        wa_id: waId,
        bid_price: bidPrice,
        bid_loc: bidLoc,
        paddle_num: paddleNum ? paddleNum : null,
      })
      return response.data
    } catch (error) {
      console.error('Auction.ts patchWinBid error', error)
      throw error
    }
  }
  // 유찰처리
  patchBidPass = async (auctionId: string, waId: string): Promise<ReplyCommSuccess> => {
    try {
      const response = await apiClient.patch(`/auction/bid/pass`, {
        a_id: auctionId,
        wa_id: waId,
      })
      return response.data
    } catch (error) {
      console.error('Auction.ts patchBidPass error', error)
      throw error
    }
  }
  // 경매 입찰자 수 조회
  // /bid/user-count/:auction_id
  getBidUserCount = async (auctionId: string): Promise<ReplyGetBidUserCount> => {
    try {
      const response = await apiClient.get(`/auction/bid/user-count/${auctionId}`)
      return response.data
    } catch (error) {
      console.error('Auction.ts getBidUserCount error', error)
      throw error
    }
  }
  //---------------------- 경매 입찰 ----------------------
}
export const apiAuction = new Auction()
