/**
 * Live Broadcast Video Redux Storage
 */

import { createSlice } from '@reduxjs/toolkit'

export type VideoState = {
  status:
    | 'NONE'
    | 'READY'
    | 'CHANNEL_CONNECT'
    | 'CHANNEL_CONNECTED'
    | 'INTERGRATED'
    | 'CHANNEL_ENTERED'
    | 'CHANNEL_LEFT'
    | 'FAILED'
  sessionId: string | null
  isWesConnected: boolean
}
const initialVideoState: VideoState = {
  status: 'NONE',
  sessionId: null,
  isWesConnected: false,
}

export const storeVideoSlice = createSlice({
  name: 'broadcastVideo',
  initialState: initialVideoState,
  reducers: {
    SET_VIDEO_CONNECTED: (state, action) => {
      state.status = 'CHANNEL_CONNECTED'
      state.isWesConnected = true
      state.sessionId = action.payload.sessionId
    },
    SET_VIDEO_DISCONNECTED: state => {
      state.status = 'NONE'
      state.isWesConnected = false
      state.sessionId = null
    },
    SET_VIDEO_REMOVE: state => {
      state.status = 'NONE'
      state.isWesConnected = false
      state.sessionId = null
    },
    SET_VIDEO_STATUS: (state, action) => {
      state.status = action.payload
    },
    SET_VIDEO_SESSION_ID: (state, action) => {
      state.sessionId = action.payload
    },
  },
})

export const { SET_VIDEO_CONNECTED, SET_VIDEO_STATUS, SET_VIDEO_SESSION_ID, SET_VIDEO_REMOVE } =
  storeVideoSlice.actions
export default storeVideoSlice.reducer
