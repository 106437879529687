import { useEffect, useState } from 'react'
import { Table, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { removeCookieToken } from '../../storage/CookieToken'
import { REMOVE_TOKEN } from '../../store/AuthToken'
import { REMOVE_AUCTIONS } from '../../store/AuctionList'
import { AxiosError } from 'axios'
import { apiAuction } from '../../api/Auction'
import { ReplyGetBidItem, ReplyGetBidList } from '../../api/Types'
import { SET_BID_LIST } from '../../store/StoreBid'
import { broadcasterSdk } from '../../ws/SpellClient'
import NumberConvert from '../../lib/utils/NumberConvert'
import { SET_LAST_BID } from '../../store/StoreLastBid'

const BidTable = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const [bidList, setBidList] = useState<ReplyGetBidList>({
  //   list: [],
  // })
  const selectedAuctionId = useSelector((state: any) =>
    state.auction.list.isLoaded && state.auction.list.selectedAuction
      ? state.auction.list.selectedAuction.id
      : null
  )
  const auctionId = selectedAuctionId && selectedAuctionId
  const isBidEmpty = useSelector((state: any) =>
    ['NONE', 'LOADING', 'LOADED'].includes(state.auction.workList.status)
  )
  const isStartLot = useSelector((state: any) => state.auction.workList.status === 'START_LOT')
  const isChangeLot = useSelector((state: any) => state.auction.workList.status === 'CHANGE_LOT')
  const isLastLot = useSelector((state: any) => state.auction.workList.status === 'LAST_LOT')
  const isEnableBid = isStartLot || isChangeLot || isLastLot

  const currentLotNum = useSelector((state: any) => state.auction.workList.currentLotNum)
  const changeLotNum = useSelector((state: any) =>
    isEnableBid || currentLotNum ? currentLotNum : null
  )
  const bidList = useSelector((state: any) => (isEnableBid ? state.auction.bidTable.list : null))
  const isRefresh = useSelector((state: any) => state.auction.bidTable.isRefresh)
  // const changeLot = useSelector(
  //   (state: any) => selectedAuctionId && state.auction.workList.currentLotNum
  // )

  // if (bidList !== undefined && bidList !== null && bidList.length > 0) {
  //   bidList.forEach((bid: any) => {
  //     if (bid.isConfirm == true) {
  //       bid.trClass = 'confirm'
  //       return
  //     }
  //   })
  // }

  /**
   * AxiosError Handler
   * @param error
   */
  const axiosErrorHandler = (error: any) => {
    console.error('AxiosError: ', error.response)
    if (error.response?.status === 401) {
      dispatch(REMOVE_TOKEN())
      dispatch(REMOVE_AUCTIONS())
      removeCookieToken()
      if (error.response?.data?.payload?.isExpiredToken === true) {
        alert('로그인 인증이 만료되었습니다. 재 로그인이 필요합니다.')
      } else if (error.response?.data?.payload?.isMissingApiKey === true) {
        alert('API Key가 없습니다. 관라자에게 문의하세요')
      } else if (error.response?.data?.payload?.isInvalidApiKey === true) {
        alert('인증서가 유효하지 않습니다. 재 로그인이 필요합니다.')
      }
      navigate('/login')
    } else alert(error.message)
  }

  // const bidList = new Array()

  const updateBidList = async (auctionId: string, lotNumber: number) => {
    try {
      const reply = await apiAuction.getBidList(auctionId, lotNumber)
      console.log('reply: ', reply)

      if (reply && reply.list && reply.list.length > 0) {
        // reply.list.map((bid: any) => {
        //   bidList.push(bid)
        // })
        dispatch(SET_BID_LIST(reply))
        dispatch(SET_LAST_BID(reply.list[0]))
        // setBidList(reply)
      } else {
        dispatch(SET_BID_LIST({ list: [] }))
        // setBidList({ list: [] })
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        axiosErrorHandler(error)
      } else {
        alert('서버와의 통신에 실패했습니다. 잠시 후 다시 시도해주세요.')
      }
    }
  }

  // changeLot이 변경되면 응찰 내역을 갱신한다.
  useEffect(() => {
    if (isStartLot) {
      updateBidList(auctionId, changeLotNum)
    }
  }, [isStartLot])

  useEffect(() => {
    if ((isChangeLot || isLastLot) && changeLotNum) {
      updateBidList(auctionId, changeLotNum)
    }
  }, [changeLotNum])

  useEffect(() => {
    if (isBidEmpty) {
      // setBidList({ list: [] })
      dispatch(SET_BID_LIST({ list: [] }))
    }
  }, [isBidEmpty])

  useEffect(() => {
    if (isRefresh) {
      updateBidList(auctionId, changeLotNum)
    }
  }, [isRefresh])

  const onlyOnce = () => {
    if (selectedAuctionId === null) return
    broadcasterSdk.addEventListener('bidded', (e: any) => {
      console.log('Broadcaster. bidded. e:', e)
      updateBidList(auctionId, e.lot)
    })
    broadcasterSdk.addEventListener('appliedBid', (e: any) => {
      console.log('Broadcaster. appliedBid. e:', e)
      updateBidList(auctionId, e.lot)
    })
  }
  useEffect(onlyOnce, [selectedAuctionId])

  // useEffect(() => {

  // if (selectedAuctionId && changeLot > 0) {
  //   updateBidList()
  // }

  console.log('[Render] BidTable')

  return (
    <Block>
      <BidTableBlock className="tableFixHead">
        {/* <Table striped bordered hover className="bidtable"> */}
        <Table bordered hover className="bidtable">
          <thead>
            <tr>
              <th className="time">시간</th>
              <th className="biduser">Online 응찰자</th>
              <th className="paddle">패들번호</th>
              <th className="price">응찰금액</th>
            </tr>
          </thead>
          <tbody>
            {!!bidList && bidList.length > 0 ? (
              bidList.map((bid: any, idx: number) => (
                <tr
                  key={bid.bid_loc + '_' + bid.bid_price}
                  className={!!bid.isWinBid ? 'winbid' : !!bid.isLastConfirm ? 'confirm' : ''}
                >
                  <td className="time">{bid.bid_time_fm}</td>
                  <td className={bid.bid_loc === 'O' ? 'biduser online' : 'biduser'}>
                    {bid.isConfirm ? '✓ ' : ''}
                    {bid.bid_loc === 'O' ? bid.name + ' ' + bid.email : '현장'}
                  </td>
                  <td className={bid.bid_loc === 'O' ? 'paddle online' : 'paddle'}>
                    {bid.paddle_num ? bid.paddle_num : '-'}
                  </td>
                  <td className="price">{NumberConvert.numberWithCommas(bid.bid_price)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="time"></td>
                <td className="biduser center">응찰 내역이 없습니다</td>
                <td className="paddle"></td>
                <td className="price"> </td>
              </tr>
            )}
          </tbody>
        </Table>
      </BidTableBlock>
    </Block>
  )
}
const Block = styled.div`
  position: relative;
`
const BidTableBlock = styled.div``
const BidTableHiddenBlock = styled.div`
  width: 100%;
  height: 100%;
  background: #333333aa;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffffae;
  font-size: 1.2em;
`
export default BidTable
