import { useSelector } from 'react-redux'

import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import Header from '../components/Header'
import WorkartList from '../components/auction/WorkartList'
import ControlPanel from '../components/auction/ControlPanel'
import VideoComponent from '../components/auction/VideoComponent'
import BidTable from '../components/auction/BidTable'
import LotInfo from '../components/auction/LotInfo'
import AuctionControll from '../components/AuctionControll'

function Home() {
  const isLoaded = useSelector((state: any) => state.auction.workList.isLoaded)
  // const isEndLot = useSelector((state: any) => state.auction.workList.status === 'END_LOT')
  const isStartedAuction = useSelector(
    (state: any) =>
      // !!state.auction.list.selectedAuction && state.auction.list.selectedAuction.started_auction
      isLoaded === true &&
      state.auction.workList.datas &&
      state.auction.workList.datas.length > 0 &&
      state.auction.workList.currentLotNum > 0
  )

  return (
    <Block>
      <Header />
      <AuctionContentsStyle>
        <Head>
          <AuctionControll />
        </Head>
        <BlockLiveStyle>
          <Row>
            <Col lg="8">
              <VideoComponent />
            </Col>
            <Col lg="4">{!isStartedAuction ? <WorkartList /> : <LotInfo />}</Col>
          </Row>
        </BlockLiveStyle>
        <BlockBottomStyle>
          <Row>
            <Col lg="8">
              <BidTable />
            </Col>
            <Col lg="4">
              <ControlPanel />
            </Col>
          </Row>
        </BlockBottomStyle>
      </AuctionContentsStyle>
    </Block>
  )
}
const Block = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`
const AuctionContentsStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`
const Head = styled.div`
  width: 100%;
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
`
const BlockLiveStyle = styled.div`
  width: 1280px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  font-size: 14px;
`
const BlockBottomStyle = styled.div`
  width: 1280px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`

export default Home
