/**
 * Auctions List Redux Storage
 */

import { createSlice } from '@reduxjs/toolkit'

const isVisibleDeleteChannelState = [
  'CREATED_CHANNEL',
  'CHANNEL_CONNECTED',
  'INTERGRATED',
  'CHANNEL_ENTERED',
  'CHANNEL_OPEND',
  // 'OPENED',
  // 'ENDED_AUCTION',
  'CLOSED',
  // 'OFFAIR',
]

export type AuctionsState = {
  isLoaded: boolean
  auctions: AuctionState[] | null
  selectedAuction: AuctionState | null
  selectedAuctionId: string | null
  selectedAuctionName: string | null
  wesWebsocketSessionId: string | null
  isVisibleDeleteChannelBtn: boolean
  isOnAir: boolean
  started_auction: boolean
  status:
    | 'NONE'
    | 'SELECTED_AUCTION'
    | 'SET_MEDIA'
    | 'CREATED_CHANNEL'
    | 'CHANNEL_CONNECTED'
    | 'INTERGRATED'
    | 'CHANNEL_ENTERED'
    | 'CHANNEL_OPEND'
    // | 'OPENED'
    // | 'ONAIR'
    // | 'OFFAIR'
    // | 'BID_STARTED'
    | 'CLOSE'
    | 'CLOSED'
}
// export type Auction = {
//   id: string
//   location: string
//   title: string
//   sub_title: string
//   live_dt: Date
//   live_dt_fm: string
//   live_date_fm: string
// }
export type AuctionState = {
  id: string
  location: string
  title: string
  sub_title: string
  live_dt: Date
  live_dt_fm: string
  live_date_fm: string
  live_status: string
  current_lot: number

  // | 'ENDED_AUCTION'
}

const initialAuctionsState: AuctionsState = {
  isLoaded: false,
  auctions: null,
  selectedAuction: null,
  selectedAuctionId: null,
  selectedAuctionName: null,
  wesWebsocketSessionId: null,
  isVisibleDeleteChannelBtn: false,
  status: 'NONE',
  isOnAir: false,
  started_auction: false,
}

export const auctionsSlice = createSlice({
  name: 'list',
  initialState: initialAuctionsState,
  reducers: {
    SET_AUCTIONS: (state, action) => {
      state.isLoaded = true
      state.status = 'SELECTED_AUCTION'
      state.auctions = action.payload.auctions
      state.selectedAuction = action.payload.auctions[0]
      state.selectedAuctionId = action.payload.auctions[0].id
      state.selectedAuctionName = action.payload.auctions[0].title
    },
    REMOVE_AUCTIONS: state => {
      state.status = 'NONE'
      state.isLoaded = false
      state.isOnAir = false
      state.started_auction = false
      state.auctions = []
      state.selectedAuction = null
      state.selectedAuctionId = null
      state.selectedAuctionName = null
      state.isVisibleDeleteChannelBtn = false
    },
    SET_SELECTED_AUCTION: (state, action) => {
      state.status = 'SELECTED_AUCTION'
      state.isOnAir = false
      state.started_auction = false
      state.selectedAuction = action.payload
      state.selectedAuctionId = action.payload.id
      state.selectedAuctionName = action.payload.title
    },
    // INIT_AUCTION_STATUS: state => {
    //   if (state.selectedAuction === null) return
    //   state.status = 'INIT_AUCTION'
    //   state.isOnAir = false
    //   state.started_auction = false
    // },
    SET_AUCTION_STATUS: (state, action) => {
      if (state.selectedAuction === null) return
      if (action.payload.status === 'SET_MEDIA' && state.status !== 'SELECTED_AUCTION') return
      state.isVisibleDeleteChannelBtn =
        isVisibleDeleteChannelState.includes(action.payload.status) && state.isOnAir === false
      state.status = action.payload.status
    },
    SET_AUCTION_STATUS_CHANNEL_CONNECTED: (state, action) => {
      if (state.selectedAuction === null) return
      if (action.payload.sessionId === null) {
        console.error('SET_WES_CHANNEL_CONNECTED: sessionId is null')
      } else {
        state.status = 'CHANNEL_CONNECTED'
        state.wesWebsocketSessionId = action.payload.sessionId
      }
    },
    SET_ONAIR: state => {
      if (state.selectedAuction === null) return
      if (state.status === 'CHANNEL_OPEND' && state.isOnAir === false) {
        state.isOnAir = true
      }
      state.isVisibleDeleteChannelBtn = !state.isOnAir
    },
    SET_OFFAIR: state => {
      if (state.selectedAuction === null) return
      if (state.status === 'CHANNEL_OPEND' && state.isOnAir === true) {
        state.isOnAir = false
      }
      state.isVisibleDeleteChannelBtn = !state.isOnAir
    },
    SET_STARTED_AUCTION: state => {
      if (state.selectedAuction === null) return
      if (state.status === 'CHANNEL_OPEND') {
        state.started_auction = true
      }
    },
    SET_UNSTARTED_AUCTION: state => {
      if (state.selectedAuction === null) return
      if (state.status === 'CHANNEL_OPEND') {
        state.started_auction = false
      }
    },
    SET_ENDED_AUCTION: state => {
      if (state.selectedAuction === null) return
      state.status = 'CLOSED'
      state.isLoaded = false
      state.isOnAir = false
      state.started_auction = false
      state.isVisibleDeleteChannelBtn = false
    },
  },
})

export const {
  SET_AUCTIONS,
  REMOVE_AUCTIONS,
  SET_SELECTED_AUCTION,
  // INIT_AUCTION_STATUS,
  SET_AUCTION_STATUS,
  SET_AUCTION_STATUS_CHANNEL_CONNECTED,
  SET_ONAIR,
  SET_OFFAIR,
  SET_STARTED_AUCTION,
  SET_UNSTARTED_AUCTION,
  SET_ENDED_AUCTION,
} = auctionsSlice.actions
export default auctionsSlice.reducer
