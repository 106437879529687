import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import styled from 'styled-components'
import { Navbar, Container, Nav, Button } from 'react-bootstrap'

import { REMOVE_SELECT_MEDIA_ALL } from '../store/StoreMedia'
import { SET_OFFAIR, REMOVE_AUCTIONS } from '../store/AuctionList'
import { REMOVE_WORKARTS } from '../store/StoreWorkartList'
import { REMOVE_TOKEN } from '../store/AuthToken'
import { removeCookieToken } from '../storage/CookieToken'

import ConfirmModal from '../components/modal/ConfirmModal'

import { apiAuction } from '../api/Auction'
import { broadcasterSdk } from '../ws/SpellClient'
import { INIT_LAST_BID } from '../store/StoreLastBid'
import { INIT_BID_LIST } from '../store/StoreBid'

const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const isAuth = !!authState.isAuth
  const isAuth = useSelector((state: any) => state.user.auth.authenticated)
  const { userName, userEmail } = useSelector((state: any) => state.user.auth)

  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false)

  const isLoaded = useSelector((state: any) => state.auction.list.isLoaded)
  const isSelected = useSelector((state: any) =>
    isLoaded && state.auction.list.status !== 'NONE' && state.auction.list.selectedAuction !== null
      ? true
      : false
  )
  const selectedAuction = useSelector((state: any) =>
    isSelected ? state.auction.list.selectedAuction : null
  )

  const isOpend = useSelector((state: any) =>
    !!state.auction.list.selectedAuction && state.auction.list.status === 'CHANNEL_OPEND'
      ? true
      : false
  )
  const isOnAir = useSelector((state: any) =>
    !!state.auction.list.selectedAuction ? state.auction.list.isOnAir : false
  )

  //---------------------------------------------------------------------------
  // Axios 에러 핸들러
  /**
   * AxiosError Handler
   * @param error
   */
  const axiosErrorHandler = (error: any) => {
    console.error('AxiosError: ', error.response)
    if (error.response?.status === 401) {
      dispatch(REMOVE_TOKEN())
      dispatch(REMOVE_AUCTIONS())
      removeCookieToken()
      if (error.response?.data?.payload?.isExpiredToken === true) {
        alert('로그인 인증이 만료되었습니다. 재 로그인이 필요합니다.')
      } else if (error.response?.data?.payload?.isMissingApiKey === true) {
        alert('API Key가 없습니다. 관라자에게 문의하세요')
      } else if (error.response?.data?.payload?.isInvalidApiKey === true) {
        alert('인증서가 유효하지 않습니다. 재 로그인이 필요합니다.')
      }
      navigate('/login')
    } else alert(error.message)
  }
  //---------------------------------------------------------------------------

  const offAir = async () => {
    if (!isOnAir) return
    if (isOpend) {
      console.log('offAir')
      broadcasterSdk.muteMedia()
      broadcasterSdk.offAir()
      dispatch(SET_OFFAIR())
    }
  }

  // 경매처기화 버튼 클릭 이벤트 핸들러
  // 경매초기화 확인 모달 오픈
  const handleOpenConfirmModal = () => {
    setIsConfirmOpen(true)
  }

  // 경매초기화 취소 버튼 클릭 이벤트 핸들러
  const handleCloseConfirmModal = () => {
    setIsConfirmOpen(false)
  }

  // 경매초기화 확인 버튼 클릭 이벤트 핸들러
  const handleConfirm = () => {
    setIsConfirmOpen(false)
    initLiveAuction()
  }

  // 경매초기화
  const initLiveAuction = async () => {
    const { id } = selectedAuction
    try {
      await broadcasterSdk.removeMyStream()
      await broadcasterSdk.leave()
      dispatch(REMOVE_SELECT_MEDIA_ALL())
      await dispatch(INIT_LAST_BID())
      await dispatch(INIT_BID_LIST())
      await dispatch(REMOVE_WORKARTS())

      const replyData = await apiAuction.initialLiveAuction(id)
      console.log('reply initLiveAuction: ', replyData)
      if (replyData.success) {
        // dispatch(SET_AUCTION_STATUS({ status: 'INIT_AUCTION' }))
        await dispatch(REMOVE_AUCTIONS())

        alert('라이브 경매가 초기화 되었습니다.')
      } else {
        alert('라이브경매 초기화에 실패하였습니다. 잠시 후 다시 시도해주세요.')
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        axiosErrorHandler(error)
      } else {
        alert('라이브경매 초기화에 실패하였습니다. 잠시 후 다시 시도해주세요.')
      }
    }
  }

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
        <Container>
          <Navbar.Brand href="/">Live Auction Management</Navbar.Brand>
          <Nav>
            <NavTextButtonStyle>
              <ButtonStyle
                variant="primary"
                onClick={() => offAir()}
                disabled={isOnAir === true ? false : true}
              >
                화면송출 종료
              </ButtonStyle>

              <ButtonStyle
                variant="primary"
                onClick={() => handleOpenConfirmModal()}
                disabled={isSelected ? false : true}
              >
                경매초기화
              </ButtonStyle>
            </NavTextButtonStyle>
            {isAuth ? (
              <NavTextStyle>
                <UserNameStyle>{userName}</UserNameStyle>
                <UserEmailStyle>({userEmail})</UserEmailStyle>
                <a href="/logout">Logout</a>
              </NavTextStyle>
            ) : (
              // <Nav.Link href="/logout">Logout</Nav.Link>
              // <Nav.Link >Logout</Nav.Link>
              <Nav.Link href="/login">login</Nav.Link>
            )}
          </Nav>
        </Container>
      </Navbar>
      <ConfirmModal
        isOpen={isConfirmOpen}
        onClose={handleCloseConfirmModal}
        onConfirm={handleConfirm}
        message="<p>경매 초기화 진행시 아래 내용을 확인하세요</p>
        <p>
          <p class='item'>경매 초기화 시 아래 내용을 진행합니다.</p>
          <p class='item'>1. LIVE 경매 화면 송출을 중단</p>
          <p class='item'>2. 경매 방송 채널 삭제</p>
          <p class='item'>3. Live 경매 진행 모든 상태 초기화</p>
          <p class='item'>4. 경매가 진행 중이 었다면 <span class='point'>모든 경매 진행 데이터가 삭제</span></p>
        </p>
        <p>위 내용을 모두 확인하였으며, 경매 초기화를 진행하시겠습니까?</p>"
      />
    </>
  )
}
const UserNameStyle = styled.span`
  font-size: 1em;
`
const UserEmailStyle = styled.span`
  padding-right: 10px;
  font-size: 0.8em;
  line-height: 1em;
`
const ButtonStyle = styled(Button)`
  width: 160px;
  margin-left: 1em;
`
const MenuItmeStyle = styled.div`
  padding: 0 1em 0 1em;
  margin-right: 2em;
  cursor: pointer;
  color: #eee;
  border: 1px solid #aaa;
  border-radius: 5px;
  text-align: center;
`
const NavTextButtonStyle = styled(Navbar.Text)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 2em;
`
const NavTextStyle = styled(Navbar.Text)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export default Header
