// import React, { useState, useEffect } from 'react'
import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal'
import styled from 'styled-components'
import { Form, Button, Row, Col, ProgressBar, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
// import spell, { getMediaStream } from '../../ws/WES'
import { broadcasterClient, broadcasterSdk } from '../../ws/SpellClient'
// import { useDidMountEffect } from '../utils/useDidMountEffect'

import { SET_AUCTION_STATUS } from '../../store/AuctionList'
import { SET_SELECT_AUDIO_OUT, SET_SELECT_MEDIA } from '../../store/StoreMedia'

// const testValue = useRecoilValue(testState)

interface VideoWidthHeight {
  width: {
    exact: number
  }
  height: {
    exact: number
  }
}

const SetMediaModal = (props: any) => {
  // Resolution ----------------------------------------------
  const qvgaConstraints: VideoWidthHeight = {
    width: { exact: 320 },
    height: { exact: 240 },
    // video: { width: { exact: 320 }, height: { exact: 240 } },
  }
  const vgaConstraints: VideoWidthHeight = {
    width: { exact: 640 },
    height: { exact: 480 },
  }
  const hdConstraints: VideoWidthHeight = {
    width: { exact: 1280 },
    height: { exact: 720 },
  }
  const fullHdConstraints: VideoWidthHeight = {
    width: { exact: 1920 },
    height: { exact: 1080 },
  }
  // Video ---------------------------------------------------

  //열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { isOpen, onRequestClose, onApplySetMedia } = props
  // const [isOpen, setIsOpen] = useState(false)

  const dispatch = useDispatch()
  // const didMount = useRef(false)

  const [isVideoShown, setIsVideoShow] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  // const [videoStream, setVideoStream] = useState<any>(null)
  const [constraints, setConstraints] = useState<VideoWidthHeight>(qvgaConstraints)
  const [isLoading, setIsLoading] = useState(true)

  // let videoView = document.getElementById('videoView') as HTMLVideoElement
  // let audioInputSelect = document.getElementById('set_audio_in') as HTMLSelectElement
  // let audioOutputSelect = document.getElementById('set_audio_out') as HTMLSelectElement
  // let videoSelect = document.getElementById('set_video') as HTMLSelectElement
  // let deviceSelectors = [audioInputSelect, audioOutputSelect, videoSelect]
  let videoView: HTMLVideoElement
  let audioInputSelect: HTMLSelectElement
  let audioOutputSelect: HTMLSelectElement
  let videoSelect: HTMLSelectElement
  let deviceSelectors: HTMLSelectElement[]
  const onLoadSelectElement = () => {
    videoView = document.getElementById('videoView') as HTMLVideoElement
    audioInputSelect = document.getElementById('set_audio_in') as HTMLSelectElement
    audioOutputSelect = document.getElementById('set_audio_out') as HTMLSelectElement
    videoSelect = document.getElementById('set_video') as HTMLSelectElement
    deviceSelectors = [audioInputSelect, audioOutputSelect, videoSelect]
  }
  // const selectedMedia = useSelector((state: any) => state.medea.selectedMedia)

  const selectedMedia = useSelector((state: any) => state.media.selected)
  const isDisableOption: boolean = useSelector(
    (state: any) =>
      !state.auction.list.selectedAuction ||
      (state.auction.list.status !== 'SELECTED_AUCTION' &&
        // state.auction.list.status !== 'INIT_AUCTION' &&
        state.auction.list.status !== 'NONE' &&
        state.auction.list.status !== 'SET_MEDIA' &&
        state.auction.list.status !== 'CREATED_CHANNEL')
  )
  let isShowSpinner = 'return <SpinnerBlock className="show" id="setMediaLoading">'
  const isLoadingView = useSelector((state: any) => isLoading)
  if (isLoadingView) {
    isShowSpinner = 'return <SpinnerBlock className="show" id="setMediaLoading">'
  }

  // useForm을 사용하여 form을 관리한다.
  const { register, handleSubmit } = useForm()

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  }

  // 에러 메시지 초기화
  const clearErrorMessage = () => {
    setErrorMessage('')
  }
  // function handleError(error: any) {
  //   console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name)
  //   setErrorMessage(`navigator.MediaDevices.getUserMedia error: ${error.message}, ${error.name}`)
  // }
  const gotStream = () => {
    const stream = broadcasterClient.getMystream()
    if (stream === null) {
      setErrorMessage(`미디어 Stream이 없습니다`)
      return
    }
    // const video = document.getElementById('videoView') as HTMLVideoElement
    // video.height = 240
    // video.srcObject = stream
    // videoView.height = 240
    videoView.srcObject = stream
    setIsVideoShow(true)
    clearErrorMessage()
    const track = stream.getVideoTracks()[0]
    const constraints = track.getConstraints()
    console.log('Result constraints: ' + JSON.stringify(constraints))
    if (constraints && constraints.width) {
      console.log('Result constraints.width: ' + constraints.width)
      // widthInput.value = constraints.width.exact
      // widthOutput.textContent = constraints.width.exact
      // } else if (constraints && constraints.width && constraints.width.min) {
      //   console.log('Result constraints.width.min: ' + constraints.width.min)
      // widthInput.value = constraints.width.min
      // widthOutput.textContent = constraints.width.min
    }
    return navigator.mediaDevices.enumerateDevices()
  }

  const gotDevices = (deviceInfos: any) => {
    // onLoadSelectElement()
    // const values = deviceSelectors.map(select => select.value)
    deviceSelectors.forEach(select => {
      while (select.firstChild) {
        select.removeChild(select.firstChild)
      }
    })
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i]
      const option = document.createElement('option')
      option.value = deviceInfo.deviceId
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label || `microphone ${audioInputSelect.length + 1}`
        audioInputSelect.appendChild(option)
      } else if (deviceInfo.kind === 'audiooutput') {
        option.text = deviceInfo.label || `speaker ${audioOutputSelect.length + 1}`
        audioOutputSelect.appendChild(option)
      } else if (deviceInfo.kind === 'videoinput') {
        option.text = deviceInfo.label || `camera ${videoSelect.length + 1}`
        videoSelect.appendChild(option)
      } else {
        console.log('Some other kind of source/device: ', deviceInfo)
      }
    }

    if (selectedMedia) {
      deviceSelectors.forEach((select, selectorIndex) => {
        const selectedIndexId: string =
          selectorIndex === 0
            ? selectedMedia.selectAudioInId
            : selectorIndex === 1
            ? selectedMedia.selectAudioOutId
            : selectedMedia.selectVideoId
        if (
          // Array.prototype.slice.call(select.childNodes).some(n => n.value === values[selectorIndex])
          Array.prototype.slice.call(select.childNodes).some(n => n.value === selectedIndexId)
        ) {
          // select.value = values[selectorIndex]
          select.value = selectedIndexId
        }
      })
    }
  }

  const attachSinkId = (element: any, sinkId: any) => {
    if (typeof element.sinkId !== 'undefined') {
      element
        .setSinkId(sinkId)
        .then(() => {
          dispatch(
            SET_SELECT_AUDIO_OUT({
              selectAudioOutId: sinkId,
            })
          )
          console.log(`Success, audio output device attached: ${sinkId}`)
        })
        .catch((e: any) => {
          let errorMessage = e
          if (e.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${e}`
          }
          console.error(e)
          // Jump back to first output device in the list as it's the default.
          audioOutputSelect.selectedIndex = 0
        })
      // .catch(error:any => {
      //   let errorMessage = error
      //   if (error.name === 'SecurityError') {
      //     errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`
      //   }
      //   console.error(errorMessage)
      //   // Jump back to first output device in the list as it's the default.
      //   audioOutputSelect.selectedIndex = 0
      // })
    } else {
      console.warn('Browser does not support output device selection.')
    }
  }

  const onChangeMediaSource = (e: any) => {
    // console.log(e.target.value)
    setMedia()
  }
  const changeAudioDestination = (e: any) => {
    // console.log(e.target.value)
    onLoadSelectElement()
    const audioDestination = audioOutputSelect.value
    attachSinkId(videoView, audioDestination)
  }
  const onchangeResolution = (e: any) => {
    // console.log(e.target.id)
    // console.log(e.target.value)
    // setIsVideoShow(false)
    clearErrorMessage()
    try {
      if (e.target.value === 'QVGA') {
        setConstraints(qvgaConstraints)
      } else if (e.target.value === 'VGA') {
        setConstraints(vgaConstraints)
      } else if (e.target.value === 'HD') {
        setConstraints(hdConstraints)
      } else if (e.target.value === 'FullHD') {
        setConstraints(fullHdConstraints)
      }
      // setMedia()
      setMedia()
    } catch (error) {
      // `/auction/delete/${id}`
      console.log(error)
      // setErrorMessage(`미디어 Stream 가져오기 실패: ${error.message} ${error.name}`)
    }
  }
  const getResoutionConstraints = (id: string) => {
    if (id === 'QVGA') {
      return qvgaConstraints
    } else if (id === 'VGA') {
      return vgaConstraints
    } else if (id === 'HD') {
      return hdConstraints
    } else if (id === 'FullHD') {
      return fullHdConstraints
    } else {
      return {}
    }
  }

  const setMedia = () => {
    if (!isOpen) return
    setIsVideoShow(false)
    onLoadSelectElement()
    // if (!audioInputSelect) return
    const audioSource = audioInputSelect.value
    const videoSource = videoSelect.value

    const selectResolutionElement = document.getElementById('set_resolution') as HTMLSelectElement
    const resoutionConstraints = getResoutionConstraints(selectResolutionElement.value)
    const mediaStreamConstraints = {
      audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
      video: {
        deviceId: videoSource ? { exact: videoSource } : undefined,
        ...resoutionConstraints,
      },
    }
    console.log('mediaStreamConstraints', mediaStreamConstraints)
    broadcasterClient
      .getNewMediaStream(mediaStreamConstraints)
      .then(gotStream)
      .then(gotDevices)
      .catch(errorMessage => {
        console.log('navigator.mediaDevices.enumerateDevices() error: ', errorMessage)
        setErrorMessage(`미디어 Stream 가져오기 실패: ${errorMessage}`)
      })
      .finally(() => {
        dispatch(
          SET_SELECT_MEDIA({
            selectAudioInId: audioSource,
            selectVideoId: videoSource,
            selectResolution: selectResolutionElement.value,
            mediaStreamConstraints: mediaStreamConstraints,
          })
        )
        const setMediaLoading = document.getElementById('setMediaLoading') as HTMLDivElement
        if (setMediaLoading && setMediaLoading.classList.contains('show')) {
          setMediaLoading?.classList.remove('show')
          setMediaLoading?.classList.add('hide')
        }
        // broadcasterClient.muteMedia()
      })
  }
  const init = () => {
    if (!isOpen) return
    setIsVideoShow(false)
    onLoadSelectElement()
    // if (!audioInputSelect) return
    const audioSource = audioInputSelect.value
    const videoSource = videoSelect.value
    const mediaStreamConstraints = {
      audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
      video: { deviceId: videoSource ? { exact: videoSource } : undefined, ...constraints },
    }
    broadcasterClient
      .getInitMediaStream(mediaStreamConstraints)
      .then(gotStream)
      .then(gotDevices)
      .catch(errorMessage => {
        console.log('navigator.mediaDevices.enumerateDevices() error: ', errorMessage)
        setErrorMessage(`미디어 Stream 가져오기 실패: ${errorMessage}`)
      })
      .finally(() => {
        const setMediaLoading = document.getElementById('setMediaLoading') as HTMLDivElement
        if (setMediaLoading && setMediaLoading.classList.contains('show')) {
          setMediaLoading?.classList.remove('show')
          setMediaLoading?.classList.add('hide')
        }
        dispatch(SET_AUCTION_STATUS({ status: 'SET_MEDIA' }))
        broadcasterSdk.unmuteMedia()
      })
  }

  useEffect(() => {
    setTimeout(() => {
      if (isOpen) {
        init()
      }
    }, 1000)
  }, [isOpen])

  // const init = (isFirst?: boolean = false) => {
  //   if (!isOpen) return
  //   setIsVideoShow(false)
  //   onLoadSelectElement()
  //   // if (!audioInputSelect) return
  //   const audioSource = audioInputSelect.value
  //   const videoSource = videoSelect.value
  //   const mediaStreamConstraints = {
  //     audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
  //     video: { deviceId: videoSource ? { exact: videoSource } : undefined, ...constraints },
  //   }
  //   spellClient
  //     .getMediaStream(mediaStreamConstraints)
  //     .then(gotStream)
  //     .then(gotDevices)
  //     .catch(errorMessage => {
  //       console.log('navigator.mediaDevices.enumerateDevices() error: ', errorMessage)
  //       setErrorMessage(`미디어 Stream 가져오기 실패: ${errorMessage}`)
  //     })
  //     .finally(() => {
  //       const setMediaLoading = document.getElementById('setMediaLoading') as HTMLDivElement
  //       if (setMediaLoading && setMediaLoading.classList.contains('show')) {
  //         setMediaLoading?.classList.remove('show')
  //         setMediaLoading?.classList.add('hide')
  //       }
  //     })
  // }

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onRequestClose}
      style={customStyles}
    >
      <TitleBlock>미디어 설정</TitleBlock>
      <BodyBlock>
        <SpinnerBlock className="show" id="setMediaLoading">
          <SpinnerStyle animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </SpinnerStyle>
        </SpinnerBlock>
        <Row>
          <Col lg="6">
            <VideoAreaStyle>
              <VideoStyle
                style={{ display: isVideoShown ? 'block' : 'none' }}
                id="videoView"
                // ref={srcObject => {
                //   srcObject = videoStream
                // }}
                autoPlay
              ></VideoStyle>
              <p id="errormessage">{errorMessage}</p>
            </VideoAreaStyle>
          </Col>

          <Col lg="6">
            <FormStyle>
              <Form.Group as={Row} className="mb-2" controlId="set_video">
                <Form.Label column sm="3">
                  Video
                </Form.Label>
                <Col sm="9">
                  <Form.Select
                    {...register('set_video')}
                    onChange={e => onChangeMediaSource(e)}
                    disabled={isDisableOption}
                  ></Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-2" controlId="set_audio_in">
                <Form.Label column sm="3">
                  Audio In
                </Form.Label>
                <Col sm="9">
                  <Form.Select
                    {...register('set_audio_in')}
                    onChange={e => onChangeMediaSource(e)}
                    disabled={isDisableOption}
                  ></Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-2" controlId="set_audio_out">
                <Form.Label column sm="3">
                  Audio Out
                </Form.Label>
                <Col sm="9">
                  <Form.Select
                    {...register('set_audio_out')}
                    onChange={e => changeAudioDestination(e)}
                    disabled={isDisableOption}
                  ></Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-2" controlId="set_resolution">
                <Form.Label column sm="3">
                  해상도
                </Form.Label>
                <Col sm="9">
                  <Form.Select
                    {...register('set_resolution')}
                    onChange={e => onchangeResolution(e)}
                    defaultValue="QVGA"
                    disabled={isDisableOption}
                  >
                    <option key="QVGA" value="QVGA">
                      QVGA
                    </option>
                    <option key="VGA" value="VGA">
                      VGA
                    </option>
                    <option key="HD" value="HD">
                      HD
                    </option>
                    <option key="FHD" value="FHD">
                      Full HD
                    </option>
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-2" controlId="set_bandwidth">
                <Form.Label column sm="3">
                  Bandwidth
                </Form.Label>
                <Col sm="9">
                  <Form.Select
                    {...register('set_bandwidth')}
                    // onChange={e => onchangeVideo(e)}
                    // disabled={isDisableSelect}
                  >
                    <option value="">Audio1</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </FormStyle>
          </Col>
        </Row>
      </BodyBlock>

      <ButtonAreaStyle>
        <ButtonStyle variant="success" onClick={onApplySetMedia}>
          확인
        </ButtonStyle>
      </ButtonAreaStyle>
    </Modal>
  )
}
const TitleBlock = styled.div`
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
  padding: 10px 20px;
  background: #ddd;
`
const BodyBlock = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  width: 800px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
`
const FormStyle = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const ButtonStyle = styled(Button)`
  width: 200px;
  margin: 0px 10px;
`
const ButtonAreaStyle = styled.div`
  display: flex;
  justify-content: center;
`
const VideoAreaStyle = styled.div`
  width: 100%;
  /* background: #dadada; */
  /* line-height: 0; */
`
const VideoStyle = styled.video`
  /* width: 100%; */
  width: 380px;
`
const SpinnerStyle = styled(Spinner)`
  position: absolute;
  top: 134px;
  left: 404px;
`
const SpinnerBlock = styled.div`
  position: absolute;
  top: 44px;
  left: 0;
  background: #00000033;
  width: 840px;
  height: 354px;
`
export default SetMediaModal
