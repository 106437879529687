// custom common button react component
// Path: src/components/comm/AppButton.tsx
// Compare this snippet from src/components/comm/AppButton.tsx:
import styled, { css } from 'styled-components'
import { Button } from 'react-bootstrap'

interface IProps {
  id?: string
  type?: 'button' | 'submit' | 'reset'
  ctype?: 'siteBid' | 'siteWinBid' | 'onlineBid' | 'onlineWinBid' | 'siteBid' | 'onAir' | 'offAir'
  height?: string
  size?: 'sm' | 'lg'
  block?: boolean
  disabled?: boolean
  width?: string
  onClick?: () => void
}

const AppButton = ({
  id,
  type,
  ctype,
  height,
  size,
  block,
  disabled,
  width,
  onClick,
  children,
}: React.PropsWithChildren<IProps>) => {
  return (
    <ButtonStyle
      id={id}
      type={type}
      ctype={ctype}
      height={height}
      size={size}
      block={block}
      disabled={disabled}
      width={width}
      onClick={onClick}
    >
      {children}
    </ButtonStyle>
  )
}

const commonStyle = css`
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: bold;
  height: 38px;
  cursor: pointer;
  background-color: #5f6d6c;
  &:hover {
    background-color: #4f5e5d;
  }
  color: #fff;
  padding-left: 8px;
  padding-right: 8px;
`

const ButtonStyle = styled(Button)`
  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `}
  ${({ width }) =>
    width === '100%'
      ? css`
          width: 100%;
          display: flex;
        `
      : css`
          width: ${width};
          display: inline-flex;
        `}
  ${commonStyle} 
  ${({ ctype }) => {
    switch (ctype) {
      case 'onlineBid':
        return css`
          background-color: #f56c62;
          &:hover {
            background-color: #f7584d;
          }
        `
      case 'onlineWinBid':
        return css`
          background-color: #f92525;
          &:hover {
            background-color: #de1313;
          }
        `
      case 'siteBid':
        return css`
          background-color: #0ec71a;
          &:hover {
            background-color: #09a813;
          }
        `
      case 'siteWinBid':
        return css`
          background-color: #17931f;
          &:hover {
            background-color: #0d7a14;
          }
        `
      case 'onAir':
        return css`
          background-color: #f92525;
          &:hover {
            background-color: #de1313;
          }
        `
      case 'offAir':
        return css`
          background-color: #f56c62;
          &:hover {
            background-color: #f7584d;
          }
        `
      default:
        return css`
          background-color: #5f6d6c;
          &:hover {
            background-color: #4f5e5d;
          }
        `
    }
  }}
  ${({ height }) =>
    height &&
    css`
      height: ${height} !important;
    `}
`
export default AppButton
