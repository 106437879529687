import React from 'react'
import ReactDOM from 'react-dom/client'
// import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

// import store from './store'
// import { rootReducer } from './store'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'

import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import './bootstrap.min.css'

import store from './store'

// redux store 정의\
export const persistor = persistStore(store) // redux store 생성

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
// Cookei와 Redeux를 사용하기 위해 CookiesProvider 와 Provider로 감싸준다.
root.render(
  // <React.StrictMode>
  <CookiesProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </CookiesProvider>
  // </React.StrictMode>
)

// root.render(
//   <React.StrictMode>
//     <CookiesProvider>
//       <Provider store={store}>
//         <PersistGate loading={true} persistor={persistor}>
//           <App />
//         </PersistGate>
//       </Provider>
//     </CookiesProvider>
//   </React.StrictMode>
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
