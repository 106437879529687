const NumberConvert = {
  // 숫자로 변환
  toNumber: (value: any): number => {
    if (typeof value === 'number') {
      return value
    }
    if (typeof value === 'string') {
      return parseInt(value, 10)
    }
    return 0
  },

  // 3자리 마다 콤마 찍기
  numberWithCommas: (x: number): string => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
}
export default NumberConvert
