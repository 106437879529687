import SpellSdk, { SpellConfig } from 'spell-client-sdk'

class SpellClient {
  spellSdk: SpellSdk
  private myConstraints: MediaStreamConstraints | null = null
  private sessionId: string | null = null

  constructor() {
    const spellUrl = process.env.REACT_APP_WES_WS_URL
    if (!spellUrl) {
      alert('REACT_APP_WES_WS_URL is not defined')
      throw new Error('REACT_APP_WES_WS_URL is not defined')
    }
    const spellConfig: SpellConfig = {
      url: spellUrl,
    }

    this.spellSdk = new SpellSdk(spellConfig)
  }

  getSpellSdk() {
    return this.spellSdk
  }

  /**
   * Get my constraints
   * @returns MediaStreamConstraints
   */
  getMyConstraints() {
    return this.myConstraints
  }

  /**
   * Get my MediaStream
   * @returns MediaStream
   */
  getMystream() {
    return this.spellSdk.getMyStream()
  }

  muteMedia() {
    this.spellSdk.muteMedia()
  }

  unmuteMedia() {
    this.spellSdk.unmuteMedia()
  }

  async getInitMediaStream(constraints: MediaStreamConstraints) {
    if (this.spellSdk.getMyStream()) {
      return this.spellSdk.getMyStream()
    } else {
      this.myConstraints = constraints
      return await this.spellSdk.getMediaStream(this.myConstraints)
    }
  }

  async getNewMediaStream(constraints: MediaStreamConstraints) {
    this.myConstraints = constraints
    if (this.spellSdk.getMyStream()) {
      this.spellSdk
        .getMyStream()!
        .getTracks()
        .forEach(track => track.stop())
    }
    return await this.spellSdk.getMediaStream(this.myConstraints)
  }

  async getMediaStream() {
    if (this.myConstraints) {
      return await this.spellSdk.getMediaStream(this.myConstraints)
    } else {
      console.error('Media Constraints is not defined')
    }
  }

  // /**
  //  * Get MediaStream
  //  * @param constraints
  //  * @returns MediaStream
  //  */
  // async getMediaStream(constraints?: MediaStreamConstraints) {
  //   if (this.spellSdk.getMyStream()) {
  //     return this.spellSdk.getMyStream()
  //   } else if (this.myConstraints) {
  //     return await this.spellSdk.getMediaStream(this.myConstraints)
  //   } else if (!this.myConstraints && constraints) {
  //     this.myConstraints = constraints
  //     if (this.spellSdk.getMyStream()) {
  //       this.spellSdk
  //         .getMyStream()!
  //         .getTracks()
  //         .forEach(track => track.stop())
  //     }
  //     return await this.spellSdk.getMediaStream(this.myConstraints)
  //   }
  // }

  // async getMediaStream(constraints: MediaStreamConstraints) {
  //   if (this.spellSdk.getMyStream()) {
  //     return this.spellSdk.getMyStream()
  //   } else if (!this.myConstraints) {
  //     this.myConstraints = constraints
  //     if (this.spellSdk.getMyStream()) {
  //       this.spellSdk
  //         .getMyStream()!
  //         .getTracks()
  //         .forEach(track => track.stop())
  //     }
  //     return await this.spellSdk.getMediaStream(constraints)
  //   } else {
  //     return await this.getResetMediaStream(constraints)
  //   }
  // }

  /**
   * Get Create MediaStream
   * @param constraints
   * @returns MediaStream
   */
  async getResetMediaStream(constraints: MediaStreamConstraints) {
    this.myConstraints = constraints
    if (this.spellSdk.getMyStream()) {
      this.spellSdk
        .getMyStream()!
        .getTracks()
        .forEach(track => track.stop())
    }
    return await this.spellSdk.getMediaStream(constraints)
    // const stream = await navigator.mediaDevices.getUserMedia(constraints)
    // console.log('### getResetMediaStream: stream', stream)
    // return stream
  }

  async deleteStream() {
    if (this.spellSdk.getMyStream()) {
      this.spellSdk
        .getMyStream()!
        .getTracks()
        .forEach(track => track.stop())
    }
  }

  // 나중에 삭제할 것
  createVideo(targetVideoId: string) {
    try {
      const video = document.getElementById(targetVideoId) as HTMLVideoElement
      video.height = 426
      video.autoplay = true
      video.srcObject = this.spellSdk.getMyStream()
    } catch (e) {
      console.error('createUserMedia error', e)
      throw e
    }
  }
}
export { SpellClient }

const broadcasterClient = new SpellClient()
export { broadcasterClient }
const broadcasterSdk = broadcasterClient.getSpellSdk()
export { broadcasterSdk }

const viewerClient = new SpellClient()
export { viewerClient }
const viewerSdk = viewerClient.getSpellSdk()
export { viewerSdk }
