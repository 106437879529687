/**
 * Auth.js 에서 선언한 reducer를 사용하기 위한 configureStore
 * 브라우저 새로고침 등으로 인해 store가 초기화 되는 경우를 대비하여
 * redux-persist를 사용하여 store를 localStorage에 저장 (sessionStorage도 가능)
 */
// import { configureStore } from '@reduxjs/toolkit'
// import authTokenReducer from './AuthToken'

// import { persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
// import { combineReducers } from 'redux'

// export default configureStore({
//   reducer: {
//     authToken: authTokenReducer,
//   },
// })

// slice reducer를 하나로 합쳐 rootReducer 생성
// export const rootReducer = combineReducers({
//   authToken: authTokenReducer,
// })

// export default persistReducer(persistConfig, rootReducer)

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from './AuthToken'
import auctionsReducer from './AuctionList'
import workartsReducer from './StoreWorkartList'
import mediaReducer from './StoreMedia'
import videoReducer from './StoreVideo'
import bidReducer from './StoreBid'
import lastBidReducer from './StoreLastBid'
import channelReducer from './StoreChannel'

//------------------ User Auth Reducer ------------------//
// slice reducer를 하나로 합쳐 rootReducer 생성
const userReducers = combineReducers({
  auth: authReducer,
})
// redux-persist 설정
const userPersistConfig = {
  key: 'user',
  // localstorage 에 저장
  storage,
  // authToken Reducer를 localStorage에 저장
  whitelist: ['auth'],
  // blacklist: [''] --> blacklist에 지정한 reducer만 제외 시킬 때 사용하는 옵션
}
// persistReducer를 사용하여 rootReducer를 감싸줌
const userPersistedReducer = persistReducer(userPersistConfig, userReducers)
//------------------ User Auth Reducer ------------------//

//------------------ Auction Reducer ------------------//
const auctionReducers = combineReducers({
  list: auctionsReducer,
  // broadcastVideo: broadcastVideoReducer,
  workList: workartsReducer,
  bidTable: bidReducer,
  lastBid: lastBidReducer,
  channel: channelReducer,
})
const auctionPersistConfig = {
  key: 'auction',
  // localstorage 에 저장
  storage,
  // authToken Reducer를 localStorage에 저장
  whitelist: ['list', 'lastBid'],
  // whitelist: [],
  // blacklist: [''] --> blacklist에 지정한 reducer만 제외 시킬 때 사용하는 옵션
}
const auctionPersistedReducer = persistReducer(auctionPersistConfig, auctionReducers)
//------------------ Auction Reducer ------------------//

const videoViewReducers = combineReducers({
  video: videoReducer,
})
const videoViewPersistConfig = {
  key: 'broadcast',
  storage,
  whitelist: [],
}
const broadcastVideoPersistedReducer = persistReducer(videoViewPersistConfig, videoViewReducers)

//------------------ WorkArt Reducer ------------------//
// const workartReducers = combineReducers({
//   list: workartsReducer,
// })
// const workartPersistConfig = {
//   key: 'workart',
//   // localstorage 에 저장
//   storage,
//   // authToken Reducer를 localStorage에 저장
//   whitelist: [],
//   // blacklist: [''] --> blacklist에 지정한 reducer만 제외 시킬 때 사용하는 옵션
// }
// const workartPersistedReducer = persistReducer(workartPersistConfig, workartReducers)
//------------------ WorkArt Reducer ------------------//

//------------------ User Auth Reducer ------------------//
// slice reducer를 하나로 합쳐 rootReducer 생성
const mediaReducers = combineReducers({
  selected: mediaReducer,
})
// redux-persist 설정
const mediaPersistConfig = {
  key: 'media',
  // localstorage 에 저장
  storage,
  // authToken Reducer를 localStorage에 저장
  // whitelist: ['selected'],
  whitelist: [],
  // blacklist: [''] --> blacklist에 지정한 reducer만 제외 시킬 때 사용하는 옵션
}
const mediaPersistedReducer = persistReducer(mediaPersistConfig, mediaReducers)
//------------------ User Auth Reducer ------------------//

// configureStore를 사용하여 store 생성
const store = configureStore({
  reducer: {
    user: userPersistedReducer,
    auction: auctionPersistedReducer,
    broadcast: broadcastVideoPersistedReducer,
    // workart: workartPersistedReducer,
    media: mediaPersistedReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export default store
