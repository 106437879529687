import axiox, { AxiosRequestConfig } from 'axios'
import {
  getCookieAccessToken,
  getCookieToken,
  setCookieAccessToken,
  setCookieToken,
} from '../storage/CookieToken'

import { apiAuth, instanceOfReplyError } from './Auth'
import { SET_TOKEN } from '../store/AuthToken'

export const apiClient = axiox.create({
  baseURL: process.env.REACT_APP_SERVER_API_BASE_URL,
  timeout: Number(process.env.REACT_APP_SERVER_API_TIMEOUT),
  headers: { ContentType: 'application/json' },
})

apiClient.interceptors.request.use(onRequest, onRequestError)
apiClient.interceptors.response.use(onResponse, onResponseError)

/**
 * onReqeust
 * Interceptor for request
 * @param config AxiosRequestConfig
 * @returns config AxiosRequestConfig
 */
function onRequest(config: AxiosRequestConfig) {
  // console.log('onRequest', config)
  const accessToken = getCookieAccessToken()
  if (!accessToken) {
    config.headers = {
      ContentType: 'application/json',
      apikey: 'Bearer ' + process.env.REACT_APP_SERVER_API_KEY,
      authorization: null,
    }
    return config
  }
  if (config.headers && accessToken) {
    config.headers = {
      ContentType: 'application/json',
      apikey: 'Bearer ' + process.env.REACT_APP_SERVER_API_KEY,
      authorization: 'Bearer ' + accessToken,
    }
    return config
  }
  return config
}

/**
 * onRequestError
 * Interceptor for request error
 * @param error
 * @returns
 */
function onRequestError(error: any) {
  console.log('onRequestError', error)
  return Promise.reject(error)
}

/**
 * onResponse
 * Interceptor for response
 * @param response
 * @returns
 */
function onResponse(response: any) {
  // console.log('onResponse', response)
  return response
}

/**
 * onResponseError
 * Interceptor for response error
 * 1. isExpiredToken 인 경우 토큰 재발급
 * 2. 재발급 실패시 로그인 페이지로 이동
 * 3. 재발급 성공시 재요청
 * @param error
 * @returns
 */
async function onResponseError(error: any) {
  console.group('onResponseError')
  // console.log(error)
  const {
    config,
    response: { status },
  } = error
  if (status === 401) {
    if (error.response.data.payload?.isExpiredToken) {
      const originalRequest = config

      console.group('refresh token')
      const replyData = await apiAuth.refreshToken(getCookieToken())
      console.log('refresh token response data:', replyData)
      if (instanceOfReplyError(replyData)) {
        console.log('instanceOfReplyError: ', replyData.message)
        return
      } else {
        setCookieAccessToken(replyData.tokens.accessToken)
        setCookieToken(replyData.tokens.refreshToken)
        SET_TOKEN(replyData.tokens.accessToken)
        console.log('set raccess_token, refresh_token success')

        originalRequest.headers = {
          ContentType: 'application/json',
          apikey: 'Bearer ' + process.env.REACT_APP_SERVER_API_KEY,
          authorization: 'Bearer ' + getCookieAccessToken(),
        }
        console.log('original re Request:', originalRequest)
        return apiClient(originalRequest)
      }
    }
    console.groupEnd()
    return Promise.reject(error)
  }
}
