/**
 * Bid Event Redux Storage
 */

import { createSlice } from '@reduxjs/toolkit'

export type LastBidItem = {
  a_id: string
  wa_id: string
  bid_price: number
  bid_loc: string
  paddle_num?: number
  bid_type?: string
  isConfirm: boolean
  lot: number
}
export type LastBid = {
  data: LastBidItem | null
}
const initialLastBidState: LastBid = {
  data: null,
}
export const lastBidSlice = createSlice({
  name: 'lastBid',
  initialState: initialLastBidState,
  reducers: {
    SET_LAST_BID: (state, action) => {
      state.data = action.payload
    },
    INIT_LAST_BID: state => {
      state.data = null
      // state.list.push(action.payload)
    },
  },
})
export const { SET_LAST_BID, INIT_LAST_BID } = lastBidSlice.actions
export default lastBidSlice.reducer
