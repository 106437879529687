import { ReactNode } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

type FormProps = {
  children: ReactNode
}

const FormContainer = ({ children, ...props }: FormProps) => {
  // const FormContainer: React.FC<React.ReactNode> = ({ children }) => {
  return (
    <Container className="py-3">
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          {children}
        </Col>
      </Row>
    </Container>
  )
}

export default FormContainer
