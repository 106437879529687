/**
 * WorkArt List Redux Storage
 */

import { createSlice } from '@reduxjs/toolkit'
import { ReplyWorkartList } from '../api/Types'

export type WorkArtState = {
  a_id: string
  wa_id: string
  lot: number
  author_nm: string
  author_eng_nm: string
  work_nm: string
  wa_img: string
  start_price: number
  estm_lwst_price: number
  estm_best_price: number
}
export type BidUnit = {
  id: string
  base_price: number
  unit_price: number
}
export type WorkArtsState = {
  isLoaded: boolean
  status: 'READY' | 'LOADING' | 'LOADED' | 'START_LOT' | 'CHANGE_LOT' | 'LAST_LOT' | 'END_LOT'
  bidUnits: BidUnit[] | null
  datas: WorkArtState[] | null
  oldCurrentLotNum: number
  currentLotNum: number
  nextLotNum: number
  prevLotNum: number
  currentWorkArt: WorkArtState | null
}

const initialWorkArtsState: WorkArtsState = {
  status: 'READY',
  isLoaded: false,
  bidUnits: null,
  datas: null,
  oldCurrentLotNum: -1,
  currentLotNum: 0,
  nextLotNum: -1,
  prevLotNum: -1,
  currentWorkArt: null,
}

export const workartsSlice = createSlice({
  name: 'workList',
  initialState: initialWorkArtsState,
  reducers: {
    SET_END_LOT: state => {
      state.status = 'END_LOT'
    },
    // SET_WORKARTS: (state, action) => {
    SET_WORKARTS: (state, action: { payload: { data: ReplyWorkartList; currentLot: number } }) => {
      state.status = 'LOADED'
      state.isLoaded = true

      // state.bidUnits = action.payload.bidUnitList
      // state.datas = action.payload.list
      // state.oldCurrentLotNum = -1
      // state.currentLotNum = 0
      // state.prevLotNum = -1
      // state.nextLotNum = state.datas && state.datas.length > 0 ? state.datas[0].lot : -1
      // state.currentWorkArt = null

      state.bidUnits = action.payload.data.bidUnitList
      state.datas = action.payload.data.list
      state.oldCurrentLotNum = -1
      state.currentLotNum = 0
      state.prevLotNum = -1
      if (action.payload.currentLot > 0) {
        state.nextLotNum = action.payload.currentLot
      } else {
        state.nextLotNum = state.datas && state.datas.length > 0 ? state.datas[0].lot : -1
      }
      state.currentWorkArt = null

      // state.bidUnits = action.payload.data.bidUnitList
      // state.datas = action.payload.data.list
      // state.oldCurrentLotNum = -1
      // if (action.payload.currentLot > 0) {
      //   state.currentLotNum = action.payload.currentLot
      //   action.payload.data.list.filter((item, index) => {
      //     if (item.lot === action.payload.currentLot) {
      //       state.currentWorkArt = item
      //       state.nextLotNum = index < state.datas!.length ? state.datas![index + 1]?.lot : -1
      //       state.prevLotNum = state.datas![index - 1]?.lot
      //     }
      //   })
      // } else {
      //   state.bidUnits = action.payload.data.bidUnitList
      //   state.datas = action.payload.data.list
      //   state.oldCurrentLotNum = -1
      //   state.currentLotNum = 0
      //   state.prevLotNum = -1
      //   state.nextLotNum = state.datas && state.datas.length > 0 ? state.datas[0].lot : -1
      //   state.currentWorkArt = null
      // }
    },
    SET_UNSTART_LOT: state => {
      state.status = 'LOADED'
      if (!state.datas || state.datas.length === 0) return
      state.oldCurrentLotNum = state.currentLotNum
      state.currentLotNum = 0
      state.nextLotNum = -1
      state.prevLotNum = -1
      state.currentWorkArt = null
    },
    REMOVE_WORKARTS: state => {
      state.status = 'READY'
      state.isLoaded = false
      state.datas = []
      state.bidUnits = []
      state.oldCurrentLotNum = -1
      state.currentLotNum = 0
      state.nextLotNum = -1
      state.prevLotNum = -1
      state.currentWorkArt = null
    },
    SET_START_LOT: (state, action) => {
      if (!state.datas || state.datas.length === 0) return
      state.status = 'START_LOT'
      state.oldCurrentLotNum = 0
      // let currentLotNum = state.datas[0].lot
      // state.currentLotNum = currentLotNum
      state.currentLotNum = action.payload.lot

      state.datas.filter((item, index) => {
        if (item.lot === action.payload.lot) {
          state.currentWorkArt = item
          state.prevLotNum = index > 0 ? state.datas![index - 1].lot : -1
          if (state.datas && index === state.datas.length - 1) {
            state.status = 'LAST_LOT'
            state.nextLotNum = -1
          } else {
            state.nextLotNum = index < state.datas!.length ? state.datas![index + 1]?.lot : -1
          }
        }
      })
      // state.prevLotNum = -1
    },
    SET_NEXT_LOT: (state, action) => {
      if (!state.datas) return
      state.status = 'CHANGE_LOT'
      state.oldCurrentLotNum = state.currentLotNum
      state.currentLotNum = action.payload.lot
      state.datas.filter((item, index) => {
        if (item.lot === action.payload.lot) {
          state.currentWorkArt = item
          state.prevLotNum = state.datas![index - 1]?.lot
          if (state.datas && index === state.datas.length - 1) {
            state.status = 'LAST_LOT'
            state.nextLotNum = -1
          } else {
            state.nextLotNum = index < state.datas!.length ? state.datas![index + 1]?.lot : -1
          }
        }
      })
    },
    SET_PREV_LOT: (state, action) => {
      if (!state.datas) return
      state.status = 'CHANGE_LOT'
      state.oldCurrentLotNum = state.currentLotNum
      state.currentLotNum = action.payload.lot
      state.datas.filter((item, index) => {
        if (item.lot === action.payload.lot) {
          state.currentWorkArt = item
          state.nextLotNum = state.datas![index + 1]?.lot
          state.prevLotNum = index > 0 ? state.datas![index - 1].lot : -1
        }
      })
    },
  },
})

export const {
  SET_WORKARTS,
  REMOVE_WORKARTS,
  SET_START_LOT,
  SET_UNSTART_LOT,
  SET_NEXT_LOT,
  SET_PREV_LOT,
  SET_END_LOT,
} = workartsSlice.actions

export default workartsSlice.reducer
