import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { apiAuction } from '../../api/Auction'
import {
  SET_STARTED_AUCTION,
  SET_UNSTARTED_AUCTION,
  REMOVE_AUCTIONS,
} from '../../store/AuctionList'
import {
  SET_END_LOT,
  SET_NEXT_LOT,
  SET_PREV_LOT,
  SET_START_LOT,
  SET_UNSTART_LOT,
} from '../../store/StoreWorkartList'
import { broadcasterSdk } from '../../ws/SpellClient'
import AppButton from '../comm/AppButton'
import NumberConvert from '../../lib/utils/NumberConvert'
import { BidUnitType } from '../../api/Types'
import SelectAuction from '../SelectAuction'
import { AxiosError } from 'axios'

import { removeCookieToken } from '../../storage/CookieToken'
import { REMOVE_TOKEN } from '../../store/AuthToken'
import {
  ADD_BID_ITEM,
  CONFIRM_BID_ITEM,
  INIT_BID_LIST,
  REFRESH_BID_LIST,
  SET_BID_PRICE_LIST,
} from '../../store/StoreBid'
import { is } from 'immer/dist/internal'

const ControlPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { register, handleSubmit } = useForm()
  const bidUnitNum: number = 20

  // const [bidPriceList, setBidPriceList] = useState<number[]>([])
  const bidPriceList = useSelector((state: any) => state.auction.bidTable.bidPriceList)
  const [selectedBidPrice, setSelectedBidPrice] = useState<number>(0)

  const selectedAuctionId = useSelector((state: any) =>
    state.auction.list.isLoaded && state.auction.list.selectedAuction
      ? state.auction.list.selectedAuction.id
      : null
  )

  // ON AIR 상태
  const isOnAir = useSelector((state: any) =>
    !!state.auction.list.selectedAuction ? state.auction.list.isOnAir : false
  )

  const isChannelOpen = useSelector(
    (state: any) =>
      !!state.auction.list.selectedAuction && state.auction.list.status === 'CHANNEL_OPEND'
  )

  const isLoaded = useSelector((state: any) => state.auction.workList.status === 'LOADED')
  const isStartLot = useSelector((state: any) => state.auction.workList.status === 'START_LOT')
  const isChangeLot = useSelector((state: any) => state.auction.workList.status === 'CHANGE_LOT')
  const isLastLot = useSelector((state: any) => state.auction.workList.status === 'LAST_LOT')
  const isEndLot = useSelector((state: any) => state.auction.workList.status === 'END_LOT')
  const isEnableBid = isStartLot || isChangeLot || isLastLot
  const lotinfo = useSelector((state: any) =>
    isEnableBid
      ? {
          Workart: state.auction.workList.currentWorkArt,
          currentLotNum: state.auction.workList.currentLotNum,
          nextLotNum: state.auction.workList.nextLotNum,
          prevLotNum: state.auction.workList.prevLotNum,
          bidUnits: state.auction.workList.bidUnits,
          startPrice: state.auction.workList.currentWorkArt.start_price,
        }
      : null
  )
  const startLotNum = useSelector(
    (state: any) => isLoaded === true && state.auction.workList.nextLotNum
  )

  // const bidTable = useSelector((state: any) => state.auction.bidTable.list)

  const startBidPrice = useSelector((state: any) =>
    isEnableBid && state.auction.bidTable.list.length === 0 ? lotinfo!.startPrice : null
  )
  // const lastBidPrice = useSelector((state: any) =>
  //   (isStartLot === true || isChangeLot === true) && state.auction.bidTable.list.length > 0
  //     ? Math.max(...state.auction.bidTable.list.map((item: any) => item.bid_price))
  //     : null
  // )
  const lastBid = useSelector((state: any) =>
    isEnableBid && state.auction.bidTable.list.length > 0 ? state.auction.bidTable.list[0] : null
  )
  // const lastBid = useSelector((state: any) =>
  //   (isStartLot === true || isChangeLot === true) && state.auction.bidTable.list.length > 0
  //     ? state.auction.bidTable.list.reduce((prev: any, current: any) => {
  //         return current.isConfirm && current.bid_price > prev.bid_price ? current : prev
  //       })
  //     : null
  // )
  // const lastConfirmBid = useSelector((state: any) =>
  //   (isStartLot === true || isChangeLot === true) && state.auction.bidTable.list.length > 0
  //     ? state.auction.bidTable.list
  //         .filter((item: any) => item.isConfirm === true)
  //         .reduce((prev: any, current: any) => {
  //           return current.bid_price > prev.bid_price ? current : prev
  //         })
  //     : null
  // )

  const lastConfirmBid = useSelector((state: any) =>
    isEnableBid && !!state.auction.bidTable.lastConfirmBid
      ? state.auction.bidTable.lastConfirmBid
      : null
  )

  /**
   * AxiosError Handler
   * @param error
   */
  const axiosErrorHandler = (error: any) => {
    console.error('AxiosError: ', error.response)
    if (error.response?.status === 401) {
      dispatch(REMOVE_TOKEN())
      dispatch(REMOVE_AUCTIONS())
      removeCookieToken()
      if (error.response?.data?.payload?.isExpiredToken === true) {
        alert('로그인 인증이 만료되었습니다. 재 로그인이 필요합니다.')
      } else if (error.response?.data?.payload?.isMissingApiKey === true) {
        alert('API Key가 없습니다. 관라자에게 문의하세요')
      } else if (error.response?.data?.payload?.isInvalidApiKey === true) {
        alert('인증서가 유효하지 않습니다. 재 로그인이 필요합니다.')
      }
      navigate('/login')
    } else alert(error.message)
  }

  // Button Event Handler -------------------------------------
  const onStartLot = async (strtLotNum: number) => {
    const resData = await updateCurrentLot(strtLotNum)
    if (resData && resData.success === true) {
      dispatch(SET_START_LOT({ lot: strtLotNum }))
      console.log('set start lot')
      // dispatch(SET_STARTED_AUCTION())
    } else if (resData && resData.message) {
      alert(resData.message)
    }
  }
  const onUnstartLot = async () => {
    const resData = await updateCurrentLot(0)
    if (resData && resData.success === true) {
      dispatch(SET_UNSTART_LOT())
      // dispatch(SET_UNSTARTED_AUCTION())
    } else if (resData && resData.message) {
      alert(resData.message)
    }
  }
  const onNextLot = async (nextLot: number) => {
    const resData = await updateCurrentLot(nextLot)
    if (resData && resData.success === true) {
      dispatch(INIT_BID_LIST())
      dispatch(SET_NEXT_LOT({ lot: nextLot }))
    } else if (resData && resData.message) {
      alert(resData.message)
    }
  }
  const onPrevLot = async (prevLot: number) => {
    const resData = await updateCurrentLot(prevLot)
    if (resData && resData.success === true) {
      dispatch(SET_PREV_LOT({ lot: prevLot }))
    } else if (resData && resData.message) {
      alert(resData.message)
    }
  }

  // 현장 입찰 버튼
  const onBidConfirmSite = async () => {
    const replyBidSite = await apiAuction.postBidSite(
      lotinfo!.Workart.a_id,
      lotinfo!.Workart.wa_id,
      selectedBidPrice
    )
    if (replyBidSite.success === false && replyBidSite.isDuplicate === true) {
      alert('이미 입찰한 금액 입니다.')
      dispatch(REFRESH_BID_LIST())
    } else if (replyBidSite.success === true) {
      broadcasterSdk.applyBid(lotinfo!.Workart.lot, selectedBidPrice)
      alert('입찰이 완료되었습니다.')
      dispatch(REFRESH_BID_LIST())
    }
    // else if (replyBidSite.success === true && replyBidSite.list) {
    //   broadcasterSdk.applyBid(lotinfo!.Workart.lot, selectedBidPrice)

    //   dispatch(ADD_BID_ITEM(replyBidSite.list))
    //   alert('입찰이 완료되었습니다.')

    //   // 선행 : bid store 생성 ts 작성
    //   // dispatch(SET_BID_SITE({ bid: selectedBidPrice }))
    // }
  }
  // 온라인 입찰 버튼
  const onBidConfirmOnline = async () => {
    if (!lastBid) {
      alert('응찰 내역이 없습니다.')
      return
    }
    if (lastBid.bid_loc !== 'O') {
      alert('현재 호가의 온라인 응찰 내역이 없습니다.')
      return
    }

    const replyBidOnline = await apiAuction.patchBidOnline(
      lotinfo!.Workart.a_id,
      lotinfo!.Workart.wa_id,
      lastBid.bid_price
    )
    if (replyBidOnline.success) {
      broadcasterSdk.applyBid(lotinfo!.Workart.lot, lastBid.bid_price)
      alert('입찰이 완료되었습니다.')
      dispatch(REFRESH_BID_LIST())
    }
  }

  // 온라인 낙찰 버튼
  const onBidWinOnline = async () => {
    if (!lastConfirmBid) {
      alert('확정된 응찰이 없습니다')
      return
    }
    if (lastConfirmBid.bid_loc === 'S') {
      alert('마지막 확정된 응찰은 현장 입찰입니다')
      return
    }
    onBidWin()
  }
  // 현장 낙찰 버튼
  const onBidWinSite = async () => {
    if (!lastConfirmBid) {
      alert('확정된 응찰이 없습니다')
      return
    }
    if (lastConfirmBid.bid_loc === 'O') {
      alert('마지막 확정된 응찰은 온라인 입찰입니다')
      return
    }
    onBidWin()
  }
  const onBidPass = async () => {
    // 유찰 처리 api 호출
    const replyBidPass = await apiAuction.patchBidPass(
      lotinfo!.Workart.a_id,
      lotinfo!.Workart.wa_id
    )
    if (replyBidPass.success) {
      alert('유찰 처리 되었습니다.')
      // onNextLot(lotinfo!.nextLotNum)
      if (isLastLot) {
        dispatch(SET_END_LOT())
        broadcasterSdk.endLot()
      } else {
        onNextLot(lotinfo!.nextLotNum)
      }
    } else {
      alert('유찰 처리에 실패하였습니다.')
    }
  }
  // 낙찰 요청
  const onBidWin = async () => {
    const replyBidWin = await apiAuction.patchBidWin(
      lastConfirmBid.a_id,
      lastConfirmBid.wa_id,
      lastConfirmBid.bid_price,
      lastConfirmBid.bid_loc,
      lastConfirmBid.paddle_num
    )
    if (replyBidWin.success) {
      if (replyBidWin.winBid) {
        const messageBefor =
          replyBidWin.winBid.bid_loc === 'O'
            ? '온라인 [' + replyBidWin.winBid.paddle_num + ']'
            : '현장'
        alert('' + messageBefor + ' 낙찰이 완료되었습니다.')
      } else {
        alert('낙찰이 완료되었습니다.')
      }
      // broadcasterSdk.applyBid(lotinfo!.Workart.lot, lastBid.bid_price)
      // dispatch(REFRESH_BID_LIST())

      if (isLastLot) {
        await dispatch(SET_END_LOT())
        broadcasterSdk.endLot()
      } else {
        onNextLot(lotinfo!.nextLotNum)
      }
    } else {
      alert('낙찰에 실패했습니다.')
    }
  }

  // const onFailedBid = (lotNum: number) => {
  //   onNextLot(lotNum)
  // }
  // Button Event Handler -------------------------------------

  /**
   * CurrentLot을 다음 Lot으로 변경 to Auction
   * @param nextLotNum
   */
  const updateCurrentLot = async (nextLotNum: number) => {
    try {
      const replyData = await apiAuction.patchCurrentLot(selectedAuctionId, nextLotNum)
      console.log('reply updateCurrentLot:', replyData)
      return replyData
    } catch (error) {
      if (error instanceof AxiosError) {
        axiosErrorHandler(error)
      } else {
        alert('서버와의 통신에 실패했습니다. 잠시 후 다시 시도해주세요.')
      }
    }
  }

  /**
   * 라이브 경매 상태 'END' 로 변경 to Auction
   * @returns
   */
  const updateAuctionEnd = async () => {
    try {
      const replyData = await apiAuction.auctionEnd(selectedAuctionId)
      console.log('reply updateAuctionEnd:', replyData)
      return replyData
    } catch (error) {
      if (error instanceof AxiosError) {
        axiosErrorHandler(error)
      } else {
        alert('서버와의 통신에 실패했습니다. 잠시 후 다시 시도해주세요.')
      }
    }
  }

  const updateBidPriceInfo = async (bidPrice: number, isStartPrice: boolean) => {
    console.group('updateBidPriceInfo')
    console.log('updateBidPriceInfo:', bidPrice)

    // setSelectedBidPrice(lotinfo!.startPrice)

    var selectItems = new Array()
    if (lotinfo && lotinfo.bidUnits && lotinfo.bidUnits.length > 0) {
      const { bidUnits } = lotinfo
      // var accPrice = bidPrice
      if (isStartPrice) {
        selectItems.push(bidPrice)
        setSelectedBidPrice(bidPrice)
      }

      console.log('bidUnits:', bidUnits)
      console.log('selectItems:', selectItems)
      for (let i = 0; i < bidUnitNum; i++) {
        bidUnits.some((unit: any, idx: number) => {
          console.log(
            'selectItems.length: [' +
              selectItems.length +
              '] bidPrice: [' +
              bidPrice +
              '] >= unit.base_price: [' +
              unit.base_price +
              ']'
          )
          if (selectItems.length === 0 && bidPrice >= unit.base_price) {
            console.log('bidPrice + unit.unit_price : ', bidPrice + unit.unit_price)
            selectItems.push(bidPrice + unit.unit_price)
            setSelectedBidPrice(bidPrice + unit.unit_price)
            return true
          } else if (
            selectItems.length > 0 &&
            selectItems[selectItems.length - 1] > unit.base_price
          ) {
            console.log(
              'selectItems[selectItems.length - 1] + unit.unit_price : ',
              selectItems[selectItems.length - 1] + unit.unit_price
            )
            selectItems.push(selectItems[selectItems.length - 1] + unit.unit_price)
            return true
          }
        })
      }
      console.log('selectItems:', selectItems)
      // setBidPriceList(selectItems)
      dispatch(SET_BID_PRICE_LIST(selectItems))
    }
    console.groupEnd()
  }

  // STATUS 변경에 따른 처리 -------------------------------------
  useEffect(() => {
    if (isStartLot) {
      console.log('isStartLot:', isStartLot)
      broadcasterSdk.startAuction(lotinfo!.currentLotNum)
    }
  }, [isStartLot])

  useEffect(() => {
    if (isChangeLot) {
      console.log('isChangeLot:', isChangeLot)
      broadcasterSdk.changeLot(lotinfo!.currentLotNum)
    }
  }, [isChangeLot])

  useEffect(() => {
    if (isLastLot) {
      console.log('isLastLot:', isLastLot)
      broadcasterSdk.changeLot(lotinfo!.currentLotNum)
    }
  }, [isLastLot])

  useEffect(() => {
    if (startBidPrice) {
      console.log('startBidPrice:', startBidPrice)
      updateBidPriceInfo(startBidPrice, true)
    }
  }, [startBidPrice])

  // useEffect(() => {
  //   if (lastBidPrice) {
  //     console.log('lastBidPrice:', lastBidPrice)
  //     updateBidPriceInfo(lastBidPrice, false)
  //   }
  // }, [lastBidPrice])

  useEffect(() => {
    if (lastConfirmBid) {
      console.log('lastConfirmBid bid_price:', lastConfirmBid.bid_price)
      updateBidPriceInfo(lastConfirmBid.bid_price, false)
    }
  }, [lastConfirmBid])

  // useEffect(() => {
  //   if (lastBid) {
  //     console.log('lastBid bid_price:', lastBid.bid_price)
  //   }
  // }, [lastBid])

  // 한번만 실행되는 useEffect
  const onlyOnce = () => {
    // WES.WS Event Handler -------------------------------------
    broadcasterSdk.addEventListener('startedAuction', (e: any) => {
      console.log('Broadcaster. startedAuction. lot:', e.lot)
    })
    broadcasterSdk.addEventListener('changedLot', (e: any) => {
      console.log('Broadcaster. changedLot. lot:', e.lot)
    })

    broadcasterSdk.addEventListener('endedAuction', (e: any) => {
      console.log('Broadcaster. endedAuction. e:', e)
    })
    // WES.WS Event Handler -------------------------------------
  }
  useEffect(onlyOnce, [])

  const selectBidPrice = async (data: any) => {
    console.log('select bid price:', data.target.value)
    setSelectedBidPrice(data.target.value)
  }

  return (
    <Block>
      <ControlBlock>
        <Block>
          <BidControlBlock>
            <Table>
              <StyledTBody>
                <tr>
                  <StyledTd width={'70%'}>
                    <AppButton
                      id="btnBidConfirmOnline"
                      ctype="onlineBid"
                      width={'100%'}
                      onClick={() => onBidConfirmOnline()}
                      // disabled={lastBid && lastBid.isConfirm === false ? false : true}
                    >
                      온라인 입찰
                    </AppButton>
                  </StyledTd>
                  <StyledTd width={'30%'}>
                    <AppButton ctype="onlineWinBid" width={'100%'} onClick={() => onBidWinOnline()}>
                      온라인 낙찰
                    </AppButton>
                  </StyledTd>
                </tr>
                <tr>
                  <StyledTdLable colSpan={2}>
                    <label>현재 호가 KRW</label>
                  </StyledTdLable>
                </tr>
                <tr>
                  <StyledTd colSpan={2} width={'100%'}>
                    {/* <StyledFormSelect aria-label="Default select example">
                      {bidPriceOptions}
                    </StyledFormSelect> */}
                    <StyledFormSelect
                      id="select-bid-price"
                      onChange={selectBidPrice}
                      aria-label="Default select example"
                    >
                      {bidPriceList && bidPriceList.length > 0 ? (
                        bidPriceList.map((price: number, idx: number) => (
                          <option key={price.toString()} data-value={price} value={price}>
                            {NumberConvert.numberWithCommas(price)}
                          </option>
                        ))
                      ) : (
                        <option>목록이 없습니다</option>
                      )}
                    </StyledFormSelect>
                  </StyledTd>
                </tr>
                <tr>
                  <StyledTd width={'70%'}>
                    <AppButton
                      ctype="siteBid"
                      width={'100%'}
                      onClick={() => onBidConfirmSite()}
                      // disabled={lastBid ? false : true}
                    >
                      현장 입찰
                    </AppButton>
                  </StyledTd>
                  <StyledTd width={'30%'}>
                    <AppButton ctype="siteWinBid" width={'100%'} onClick={() => onBidWinSite()}>
                      현장 낙찰
                    </AppButton>
                  </StyledTd>
                </tr>
              </StyledTBody>
            </Table>
          </BidControlBlock>
          {isEnableBid || isEndLot || !isOnAir ? (
            ''
          ) : (
            <BidControlHiddenBlock>경매 시작 후 활성화 됩니다</BidControlHiddenBlock>
          )}
        </Block>

        <Table>
          <StyledTBody>
            <tr>
              <StyledTd>
                {isChangeLot && lotinfo!.prevLotNum > 0 ? (
                  <AppButton width={'100%'} onClick={() => onPrevLot(lotinfo!.prevLotNum)}>
                    {' '}
                    &lt;{' '}
                  </AppButton>
                ) : isEnableBid && lotinfo!.prevLotNum === -1 ? (
                  <AppButton width={'100%'} onClick={() => onUnstartLot()}>
                    {' '}
                    &lt;{' '}
                  </AppButton>
                ) : (
                  <AppButton width={'100%'} disabled>
                    {' '}
                    &lt;{' '}
                  </AppButton>
                )}
              </StyledTd>
              <StyledTd>
                <StyledLotLabel>LOT {isEnableBid ? lotinfo!.currentLotNum : 0}</StyledLotLabel>
              </StyledTd>
              <StyledTd width={'30%'}>
                {isLoaded ? (
                  <AppButton width={'100%'} onClick={() => onStartLot(startLotNum)}>
                    경매시작 &gt;
                  </AppButton>
                ) : isEnableBid ? (
                  <AppButton width={'100%'} onClick={() => onBidPass()}>
                    {' '}
                    유찰 &gt;{' '}
                  </AppButton>
                ) : (
                  <AppButton width={'100%'}>경매시작 &gt;</AppButton>
                )}
              </StyledTd>
            </tr>
          </StyledTBody>
        </Table>
      </ControlBlock>
      {isOnAir ? '' : <ControlHiddenBlock>ON AIR 후 활성화됩니다.</ControlHiddenBlock>}
      {isEndLot ? <ControlHiddenBlock>경매 종료</ControlHiddenBlock> : ''}
    </Block>
  )
}
const Block = styled.div`
  position: relative;
`
const ControlBlock = styled.div``
const ControlHiddenBlock = styled.div`
  width: 100%;
  height: 100%;
  background: #333333aa;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffffae;
  font-size: 1.2em;
`
const BidControlBlock = styled.div``
const BidControlHiddenBlock = styled.div`
  width: 100%;
  height: 100%;
  background: #333333aa;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffffae;
  font-size: 1.2em;
`
const StyledTBody = styled.thead`
  font-size: 0.8em;
`
const StyledTd = styled.td`
  border-style: none;
`
const StyledTdLable = styled.td`
  border-style: none;
  padding-bottom: 0 !important;
  padding-top: 16px !important;
`
const StyledFormSelect = styled(Form.Select)`
  /* height: 76px; */
`
const StyledLotLabel = styled.label`
  font-size: 1.5em;
  font-weight: bold;
  width: 100%;
  text-align: center;
  line-height: 36px;
  border: solid 1px #5f6d6c;
`
export default ControlPanel
