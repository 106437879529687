import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import Timer from '../comm/Timer'
import DateConvert from '../../lib/utils/DateConvert'
import { count } from 'console'

import { viewerSdk } from '../../ws/SpellClient'
import { SET_VIDEO_CONNECTED, SET_VIDEO_STATUS, SET_VIDEO_REMOVE } from '../../store/StoreVideo'
import { IntergrateUserType } from '../../api/Types'

import { removeCookieToken } from '../../storage/CookieToken'
import { REMOVE_TOKEN } from '../../store/AuthToken'
import { REMOVE_AUCTIONS } from '../../store/AuctionList'
import { apiUser } from '../../api/UserInterface'
import { AxiosError } from 'axios'

interface countDown {
  seconds: number
  isInit: boolean
  deadLine: number
}
interface readyVideo {
  count: number
  isReady: boolean
}

const VideoComponent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isVideoShown, setIsVideoShow] = useState(false)

  // selector ------------------------------------
  const status = useSelector((state: any) => state.broadcast.video.status)
  const sessionId = useSelector((state: any) => state.broadcast.video.sessionId ?? null)

  const audioOutId = useSelector((state: any) => state.media.selected.selectAudioOutId ?? null)

  // const isViewCountdown = useSelector((state: any) =>
  //   state.auction.list.selectedAuction
  //     ? state.auction.list.selectedAuction.status === 'CHANNEL_OPEND' ||
  //       state.auction.list.selectedAuction.status === 'OFFAIR'
  //       ? true
  //       : false
  //     : false
  // )
  const isViewCountdown = useSelector((state: any) =>
    state.auction.list.selectedAuction
      ? state.auction.list.status === 'CHANNEL_OPEND' &&
        state.auction.list.isOnAir === false
        ? true
        : false
      : false
  )
  const auction = useSelector((state: any) =>
    state.auction.list.selectedAuction &&
    state.auction.list.status === 'CHANNEL_OPEND'
      ? state.auction.list.selectedAuction
      : null
  )
  const selectedAuction = useSelector((state: any) => state.auction.list.selectedAuction)
  // const isOnAir = useSelector((state: any) =>
  //   state.auction.list.selectedAuction
  //     ? state.auction.list.selectedAuction.status === 'ONAIR'
  //       ? true
  //       : false
  //     : false
  // )
  const isOnAir = useSelector(
    (state: any) =>
      !!state.auction.list.selectedAuction && state.auction.list.isOnAir
  )
  // const isOffAir = useSelector(
  //   (state: any) => state.auction.list.selectedAuction?.status === 'OFFAIR'
  // )
  const isClosedAuction = useSelector((state: any) =>
    state.auction.list.selectedAuction
      ? state.auction.list.status === 'CLOSED'
        ? true
        : false
      : false
  )
  const { userId, userName, isManager, userEmail } = useSelector((state: any) => state.user.auth)

  // let liveDtTime = auction ?? new Date(auction.live_dt_fm).getTime()
  // selector ------------------------------------

  // CountDown ------------------------------------
  const [countdownSeconds, setCountdownSeconds] = useState<countDown>({
    seconds: -1,
    isInit: false,
    deadLine: 0,
  })
  const countDown = () => {
    const liveDtTime = countdownSeconds.deadLine
      ? countdownSeconds.deadLine
      : new Date(auction.live_dt_fm).getTime()
    const nowTime = new Date().getTime()
    let seconds = liveDtTime - nowTime
    setCountdownSeconds({ seconds: seconds, isInit: true, deadLine: liveDtTime })
  }
  useEffect(() => {
    const interval = setInterval(() => {
      if (countdownSeconds.seconds < 0) clearInterval(interval)
      else {
        countDown()
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [countdownSeconds])
  // CountDown ------------------------------------

  // READY 상태일때 isInit is false 일 때만 카운트다운 시작
  if (status === 'READY' && countdownSeconds.isInit === false) {
    countDown()
  }

  // AxiosError ------------------------------------
  /**
   * AxiosError Handler
   * @param error
   */
  const axiosErrorHandler = (error: any) => {
    console.error('AxiosError: ', error.response)
    if (error.response?.status === 401) {
      dispatch(REMOVE_TOKEN())
      dispatch(REMOVE_AUCTIONS())
      removeCookieToken()
      if (error.response?.data?.payload?.isExpiredToken === true) {
        alert('로그인 인증이 만료되었습니다. 재 로그인이 필요합니다.')
      } else if (error.response?.data?.payload?.isMissingApiKey === true) {
        alert('API Key가 없습니다. 관라자에게 문의하세요')
      } else if (error.response?.data?.payload?.isInvalidApiKey === true) {
        alert('인증서가 유효하지 않습니다. 재 로그인이 필요합니다.')
      }
      navigate('/login')
    } else alert(error.message)
  }
  // AxiosError ------------------------------------

  const resetState = () => {
    dispatch(SET_VIDEO_REMOVE())
    // viewerSdk.removeEventListener('connected', connectedEventHandler)
    // viewerSdk.removeEventListener('enterSuccess', enterSuccessEventHandler)
  }

  /**
   * WES Connect
   *  - WES Websocket connect
   *  - connect event Handler 연결
   */
  const handleWesConnect = async () => {
    console.log('handleWesConnect')
    // viewerSdk.addEventListener('connected', connectedEventHandler)
    await viewerSdk.connect()
  }

  /**
   * CHANNEL_CONNECTED 상태
   * - WES API IntergrateUser 호출
   */
  const handleChannelConnected = async () => {
    // console.group('Viewer. channel connected')
    const user: IntergrateUserType = {
      userId: -999,
      userName,
      isManager,
      userAttr: JSON.stringify({ userEmail }),
      channelId: selectedAuction.id,
    }
    try {
      if (sessionId === null) {
        console.error('sessionId is null')
        resetState()
        alert('중계화면 연결에 실패했습니다. 중계화면을 다시 연결하세요.')
      } else {
        console.log('call intergrateUser', user)
        const replyData = await apiUser.intergrateUser(sessionId, user)
        console.log('reply intergrateUser', replyData)
        if (replyData) {
          dispatch(SET_VIDEO_STATUS('INTERGRATED'))
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        axiosErrorHandler(error)
        resetState()
      } else {
        resetState()
        alert('중계화면 연결에 실패했습니다. 중계화면을 다시 연결하세요.')
      }
    }
    // console.groupEnd()
  }

  /**
   * INTERGRATED 상태
   * - WES Channel enter
   * - enterSuccess event Handler 연결
   */
  const handleIntergrated = () => {
    // viewerSdk.addEventListener('enterSuccess', enterSuccessEventHandler)
    viewerSdk.enter(selectedAuction.id)
  }

  const closeViewer = () => {
    viewerSdk.leave()
    resetState()
  }

  // const handleChannelEntered = () => {

  // }

  useEffect(() => {
    switch (status) {
      case 'READY': {
        handleWesConnect()
        break
      }
      case 'CHANNEL_CONNECTED':
        handleChannelConnected()
        break
      case 'INTERGRATED':
        console.log('INTERGRATED..')
        handleIntergrated()
        break
      case 'CHANNEL_ENTERED':
        console.log('CHANNEL_ENTERED..')
        break
    }
  }, [status])

  const attachSinkId = (element: any, sinkId: any) => {
    if (typeof element.sinkId !== 'undefined') {
      element
        .setSinkId(sinkId)
        .then(() => {
          console.log(`Success, audio output device attached: ${sinkId}`)
        })
        .catch((e: any) => {
          let errorMessage = e
          if (e.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${e}`
          }
          console.error(e)
        })
    } else {
      console.warn('Browser does not support output device selection.')
    }
  }

  const onlyOnce = () => {
    console.group('Viewer. Regist Event Listener')
    console.log('======= Only Once =======')
    console.groupEnd()
    viewerSdk.addEventListener('connected', (e: any) => {
      console.group('Viewer. channel connected')
      console.log('sessionId:', e.sessionId)
      console.groupEnd()
      dispatch(SET_VIDEO_CONNECTED({ sessionId: e.sessionId }))
    })
    viewerSdk.addEventListener('enterSuccess', (e: any) => {
      console.group('Viewer. enterSuccess')
      console.log(e)
      console.groupEnd()
      dispatch(SET_VIDEO_STATUS('CHANNEL_ENTERED'))
    })
    viewerSdk.addEventListener('entered', e => {
      console.group('Viewer. entered')
      console.log(e)
      console.groupEnd()
    })
    viewerSdk.addEventListener('left', e => {
      console.group('Viewer. leave')
      console.log(e)
      console.groupEnd()
    })
    viewerSdk.addEventListener('remoteStreamChanged', e => {
      console.group('Viewer. remoteStreamChanged')
      console.log(e)
      const stream = viewerSdk.getRemoteStreamsById(e.sessionId)
      if (stream) {
        // add video tag to body and set stream
        const video = document.getElementById('video') as HTMLVideoElement
        if (video) {
          video.srcObject = stream
          if (audioOutId) {
            attachSinkId(video, audioOutId)
          }
        }
      }
      console.groupEnd()
    })
  }
  useEffect(onlyOnce, [])

  const [isReadyVideo, setIsReadyVideo] = useState<readyVideo>({
    count: 0,
    isReady: false,
  })
  const playViewer = () => {
    console.log('playViewer')
    const video = document.getElementById('video') as HTMLVideoElement
    console.log('video', video)
    console.log('sessionId', sessionId)
    if (!!video && !!sessionId) {
      const stream = viewerSdk.getRemoteStreamsById(sessionId)
      console.log('stream', stream)
      if (stream) {
        video.srcObject = stream
      }
    } else {
      console.log('----')
      setIsReadyVideo({ count: isReadyVideo.count + 1, isReady: false })
    }
  }

  useEffect(() => {
    if (isOnAir === true) {
      setIsVideoShow(true)
    } else {
      setIsVideoShow(false)
    }
  }, [isOnAir])

  useEffect(() => {
    if (isClosedAuction === true) {
      // setIsVideoShow(false)
      // closeViewer()
    }
  }, [isClosedAuction])

  return (
    <Block>
      {isViewCountdown ? <Timer seconds={countdownSeconds.seconds} /> : null}
      <VideoStyle
        style={{ display: isVideoShown && isViewCountdown === false ? 'block' : 'none' }}
        id="video"
        autoPlay
      ></VideoStyle>
      {isViewCountdown === false && isVideoShown === false ? <BlankBlock>&nbsp;</BlankBlock> : null}
    </Block>
  )
}
const Block = styled.div`
  width: 100%;
  line-height: 0;
  display: flex;
  justify-content: center;
  background-color: #000000;
`
const VideoStyle = styled.video`
  /* width: 100%;
 */
  /* width: 840px; */
  height: 476px;
  background-color: #000000aa;
  //
`
const BlankBlock = styled.div`
  width: 100%;
  height: 422.66px;
  background-color: #000000aa;
`
export default VideoComponent
